/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { Value } from "../../google/protobuf/struct";
import { Timestamp } from "../../google/protobuf/timestamp";
import { messageTypeRegistry } from "../../typeRegistry";
import { PublicKey, SignatureAlgorithm } from "./cryptopb/cryptopb";

export const protobufPackage = "idp";

export enum Mechanism {
  UNKNOWN = 0,
  USERNAME = 10,
  PASSWORD = 1,
  EMAIL = 2,
  KERBEROS = 3,
  TOTP = 4,
  WEBAUTHN_FACTOR = 5,
  WEBAUTHN = 7,
  ATTESTATION = 6,
  CONFIRM_TRUSTED_DEVICE = 8,
  PROOF_OF_SHARK = 9,
  OIDC = 11,
  PHONE = 12,
  IMPERSONATION = 13,
  UNRECOGNIZED = -1,
}

export enum Trust {
  /** NONE - We have no information if this factor belongs to the user */
  NONE = 0,
  /** VERIFIED - The user has proven to be able to use this factor */
  VERIFIED = 1,
  /** VETTED - This factor has been manually vetted to belong to the user */
  VETTED = 3,
  UNRECOGNIZED = -1,
}

export enum GeneratedPasswordFamily {
  SHORT = 0,
  PASSPHRASE = 1,
  MACHINE = 3,
  UNRECOGNIZED = -1,
}

export interface ClientProfile {
  $type: "idp.ClientProfile";
  displayName: string;
  logoUrl: string;
}

export interface UserProfile {
  $type: "idp.UserProfile";
  name: string;
  givenName: string;
  familyName: string;
  middleName: string;
  nickname: string;
  preferredUsername: string;
  profile: string;
  picture: string;
  website: string;
  gender: string;
  birthdate: string;
  zoneinfo: string;
  locale: string;
  updatedAt: number;
}

/** Stored */
export interface User {
  $type: "idp.User";
  /**
   * id is an unchanging unique global identifier for a user. It is recommended
   * that this is a random value with an entropy of at least 128 bits. It should
   * not contain PII.
   */
  id: string;
  /**
   * If set, indicates that this user is managed by an external system. Blocks
   * accesses from the administrative interface and API to modify and/or delete
   * the user.
   */
  external: boolean;
  status: User_Status;
  totpAuthenticator: TOTPAuthenticator[];
  webauthnAuthenticator: WebauthnAuthenticator[];
  emailAddress: EmailAddress[];
  phoneNumber: PhoneNumber[];
  password: Password | undefined;
  profile: UserProfile | undefined;
  memberOf: string[];
  claim: { [key: string]: any | undefined };
  /**
   * If this user has state GHOST, this is set to the timestamp when it
   * transitioned to that state. Cannot be set externally or through API.
   */
  ghostSince: Date | undefined;
}

export enum User_Status {
  /** OK - OK means the user is active and all features are enabled. */
  OK = 0,
  /**
   * DISABLED - DISABLED means the user is prevented from logging in and will get an
   * error indicating that the account has been disabled.
   */
  DISABLED = 1,
  /**
   * GHOST - GHOST means to pretend the user account does not exist. This is used for
   * keeping data about users which are no longer part of an external sync
   * system for a limited period of time.
   */
  GHOST = 2,
  UNRECOGNIZED = -1,
}

export interface User_ClaimEntry {
  $type: "idp.User.ClaimEntry";
  key: string;
  value: any | undefined;
}

export interface AuthContinue {
  $type: "idp.AuthContinue";
  prompt: string;
  nextMechanism: Mechanism[];
  done: boolean;
  /**
   * Hint containing the mechanism the user most likely wants to use. Can be
   * used to preselect the mechanism on the frontend. Other mechanisms still
   * need to be accessible by the user, they must not be hidden.
   */
  mechanismHint: Mechanism;
  hasUsernames: boolean;
  enrollPassword: boolean;
  enrollTotp: boolean;
  totpAuthenticator: TOTPAuthenticator[];
  enrollWebauthn: boolean;
  webauthnAuthenticator: WebauthnAuthenticator[];
  enrollEmail: boolean;
  emailAddress: EmailAddress[];
  emailEnrollment: EmailEnrollment | undefined;
  nonce: Uint8Array;
  newSessionToken: string;
  userProfile: UserProfile | undefined;
  userId: string;
}

export interface EmailEnrollment {
  $type: "idp.EmailEnrollment";
  required: boolean;
  domains: string[];
  notDomains: string[];
}

export interface Branding {
  $type: "idp.Branding";
  /** Brand of the organization. Should generally be its name. */
  brand: string;
  /** Name of the identity provider. */
  name: string;
  /**
   * Name of the logo file in the branding directory. Should be displayable by
   * normal browsers, i.e. be a JPEG, PNG or SVG.
   */
  logoFilename: string;
  /** Controls how the brand identity is displayed */
  logoStyle: Branding_LogoStyle;
  /** CSS background property. */
  background: string;
  /** Color of the buttons */
  buttonColor: string;
  /**
   * Text the user is shown when the IDP detects an error only fixable by an
   * administrator. Should generally contain a way to contact the support of
   * your organization.
   */
  supportContactText: string;
  /**
   * Text the user is shown when the IDP denies access to a resource. Should
   * generally contain a way to contact the support of your organization.
   */
  accessDeniedText: string;
  /** URL to a privacy policy */
  privacyPolicy: string;
}

export enum Branding_LogoStyle {
  /** BRAND_NAME_ONLY - Only shows textual brand name */
  BRAND_NAME_ONLY = 0,
  /** BRAND_NAME_WITH_LOGO - Shows logo to the left of textual brand name */
  BRAND_NAME_WITH_LOGO = 1,
  /** WORDMARK - Shows only wordmark logo */
  WORDMARK = 2,
  UNRECOGNIZED = -1,
}

/** Password-related */
export interface AuthPasswordRequest {
  $type: "idp.AuthPasswordRequest";
  authCtx: AuthContext | undefined;
  password: string;
}

export interface AuthPasswordResponse {
  $type: "idp.AuthPasswordResponse";
  result: AuthPasswordResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthPasswordResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  WRONG_PASSWORD = 2,
  /** MUST_CHANGE_POLICY - IDP policy requires password change */
  MUST_CHANGE_POLICY = 3,
  /** MUST_CHANGE_EXPOSED - Password has been exposed and must be changed */
  MUST_CHANGE_EXPOSED = 4,
  RATE_LIMITED = 5,
  LOCKED_OUT = 6,
  /** UNAVAILABLE - Mechanism is unavailable for this SID (for example */
  UNAVAILABLE = 7,
  UNRECOGNIZED = -1,
}

export interface ChangePasswordRequest {
  $type: "idp.ChangePasswordRequest";
  /**
   * ID of the user whose password is being changed. If left empty the
   * authenticated user is used. If the calling entity doesn't have the
   * idp.internal/pwinterop or idp.internal/admin roles calls will be rejected
   * with PermissionDenied if the user_id is not either empty or set to the
   * authenticated user.
   */
  userId: string;
  /**
   * Set to true if the request comes from a pwinterop service. This is used to
   * break the pwinterop loop (pwinterop->IDP->pwinterop ...) by not forwarding
   * any change with this set back to the pwinterop plugin. Setting this to true
   * without the idp.internal/pwinterop scope will be denied.
   */
  fromPwinterop: boolean;
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordResponse {
  $type: "idp.ChangePasswordResponse";
}

/**
 * Password represents a password a user can use to identify itself.
 *
 * For security reasons a key is derived from the user's password and a per-user
 * `salt` using the key derivation function (KDF) in `algorithm` and only the
 * derived key is stored. Since KDFs are not reversible it is impossible to find
 * the user's password faster than a brute-force search against the KDF. Thus
 * the ideal KDF is slow and memory-hard to make such brute-forcing as hard as
 * possible.
 *
 * Because the original password is not stored it is important that the password
 * passed into the KDF is bit-for-bit equal as otherwise the derived key won't
 * match and the user can't authenticate. To ensure this, the password is
 * processed into PRECIS class OpaqueString before hashing. This takes care of
 * various forms of normalization including converting to Unicode Normal Form C.
 */
export interface Password {
  $type: "idp.Password";
  algorithm: Password_Algorithm;
  derivedKey: Uint8Array;
  salt: Uint8Array;
  /**
   * Time complexity tunable of the algorithm used. Meaning of the value depends
   * on the algorithm used. If unused on a given algorithm it must be zero.
   */
  timeComplexity: number;
  /**
   * Memory complexity tunable of the algorithm used. Meaning of the value
   * depends on the algorithm used. If unused on a given algorithm it must be
   * zero.
   */
  memoryComplexity: number;
  /**
   * Amount of parallel computations to use to calculate the KDF. Meaning of
   * the value depends on the algorithm used. If unused on a given algorithm
   * must be zero.
   */
  parallelism: number;
  /**
   * Estimated entropy of the password in bits. Must be set to zero (+0.0) if
   * unknown.
   */
  estimatedEntropyBits: number;
  breached: boolean;
  /**
   * Time at which the password was last changed. This value only changes when
   * the user changes the password to a different one, not when another
   * algorithm is used or the estimated entropy changes.
   */
  lastChanged:
    | Date
    | undefined;
  /**
   * Time at which this password was last entered correctly to authenticate the
   * user.
   */
  lastUse:
    | Date
    | undefined;
  /** If set, forces the user to change the password on next authentication. */
  forceChange: boolean;
}

export enum Password_Algorithm {
  /**
   * NONE - The user does not have a password set. The password auth mechanism will
   * fail unless enrolling passwords is allowed.
   */
  NONE = 0,
  /**
   * ARGON2ID - Password is stored as an Argon2id hash. This is the default algorithm,
   * everything will be migrated to it. Argon2id is memory-hard and free from
   * timing attacks. If parallelism is unset/zero, it is assumed to be 4.
   */
  ARGON2ID = 1,
  /**
   * ARGON2I - Variant of ARGON2ID with no data-dependent memory accesses. Trades off
   * cracking resistance for better side-channel resistance.
   */
  ARGON2I = 9,
  /**
   * KRB5_AES128_SHA - Kerberos 5 AES128_CTS_HMAC_SHA1_96 hash, iteration count is stored
   * in time_complexity. memory_complexity is unused and must be zero.
   */
  KRB5_AES128_SHA = 3,
  /**
   * KRB5_AES256_SHA - Kerberos 5 AES256_CTS_HMAC_SHA1_96 hash, iteration count is stored
   * in time_complexity. memory_complexity is unused and must be zero.
   */
  KRB5_AES256_SHA = 4,
  /**
   * SHA2_CRYPT - SHA-crypt hash based on the SHA2 hash family as specified in
   * https://akkadia.org/drepper/SHA-crypt.txt. rounds are stored in
   * time_complexity and the zero value is equal to 5000. memory_complexity is
   * unused and must be zero. Note that the derived key does not contain the
   * final permutation and encoding.
   * https://pthree.org/2018/05/23/do-not-use-sha256crypt-sha512crypt-theyre-dangerous/
   */
  SHA2_CRYPT = 5,
  /**
   * DES_CRYPT - DES crypt hash based on the repeated application of the DES key schedule.
   * All complexity parameters must be zero.
   */
  DES_CRYPT = 6,
  /**
   * BCRYPT - bcrypt hash based on the Blowfish cipher. time_complexity is the number
   * of rounds. Relatively safe, but has various quirks.
   */
  BCRYPT = 8,
  /**
   * OPAQUE - OPAQUE is an asymmetric password-authenticated key exchange protocol.
   * This means it does not run the KDF on the server, but on the client. The
   * server never sees the password.
   * This is currently incompatible with server-side strength checking.
   */
  OPAQUE = 7,
  /**
   * ORACLE - An external service acts as an oracle. Service configuration happens
   * out-of-band. Should only be used for migrations as it is generally
   * vulnerable to timing attacks.
   */
  ORACLE = 2,
  UNRECOGNIZED = -1,
}

/**
 * SecretRisk represents the risk that a secret is guessed by an attacker in a
 * given timeframe.
 */
export interface SecretRisk {
  $type: "idp.SecretRisk";
  lastUpdated:
    | Date
    | undefined;
  /** Available "failed attempt" tokens until risk reaches threshold */
  tokenBucket: number;
  /** Set if this secret is already locked out */
  lockedOut: boolean;
}

/** TOTP-related */
export interface TOTPAuthenticator {
  $type: "idp.TOTPAuthenticator";
  id: Uint8Array;
  name: string;
  /** Defaults to 6 */
  digits: number;
  /** Default 30s */
  stepDuration: number;
  hash: TOTPAuthenticator_Hash;
  /** Never exposed to the outside after registration */
  secret: Uint8Array;
  /**
   * Time at which this TOTP authenticator was last entered correctly to
   * authenticate the user.
   */
  lastUse: Date | undefined;
}

export enum TOTPAuthenticator_Hash {
  SHA1 = 0,
  SHA256 = 1,
  SHA512 = 2,
  UNRECOGNIZED = -1,
}

export interface AuthTOTPRequest {
  $type: "idp.AuthTOTPRequest";
  authCtx: AuthContext | undefined;
  authenticatorId: Uint8Array;
  code: number;
}

export interface AuthTOTPResponse {
  $type: "idp.AuthTOTPResponse";
  result: AuthTOTPResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthTOTPResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  WRONG_CODE = 2,
  ALREADY_USED = 3,
  RATE_LIMITED = 4,
  LOCKED_OUT = 5,
  UNRECOGNIZED = -1,
}

export interface AddTOTPAuthenticatorRequest {
  $type: "idp.AddTOTPAuthenticatorRequest";
  authenticator: TOTPAuthenticator | undefined;
  code: number;
}

export interface AddTOTPAuthenticatorResponse {
  $type: "idp.AddTOTPAuthenticatorResponse";
}

/** WebAuthn related */
export interface WebauthnAuthenticator {
  $type: "idp.WebauthnAuthenticator";
  /** WebauthN credential ID */
  id: Uint8Array;
  /** Human-readable name of authenticator assigned by user */
  name: string;
  /** Private fields */
  algorithm: SignatureAlgorithm;
  publicKey: PublicKey | undefined;
  attestationData: Uint8Array;
  /** Dynamic fields */
  counter: number;
  /**
   * Time at which this WebauthN authenticator was last entered correctly to
   * authenticate the user.
   */
  lastUse: Date | undefined;
}

export interface AddWebauthnRequest {
  $type: "idp.AddWebauthnRequest";
  name: string;
  id: Uint8Array;
  clientDataJson: Uint8Array;
  attestationObject: Uint8Array;
}

export interface AddWebauthnResponse {
  $type: "idp.AddWebauthnResponse";
}

export interface AuthWebauthnRequest {
  $type: "idp.AuthWebauthnRequest";
  authCtx: AuthContext | undefined;
  id: Uint8Array;
  clientDataJson: Uint8Array;
  authenticatorData: Uint8Array;
  signature: Uint8Array;
  userHandle: Uint8Array;
}

export interface AuthWebauthnResponse {
  $type: "idp.AuthWebauthnResponse";
  result: AuthWebauthnResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthWebauthnResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  ERROR = 2,
  UNRECOGNIZED = -1,
}

/** Mail related */
export interface SendAuthMailRequest {
  $type: "idp.SendAuthMailRequest";
  authCtx: AuthContext | undefined;
  type: SendAuthMailRequest_Type;
  /** Only set if type = RESET */
  mechanismToBeReset: Mechanism;
  id: Uint8Array;
}

export enum SendAuthMailRequest_Type {
  AUTHENTICATION = 0,
  VERIFICATION = 1,
  RESET = 2,
  UNRECOGNIZED = -1,
}

export interface SendAuthMailResponse {
  $type: "idp.SendAuthMailResponse";
}

export interface EmailAddress {
  $type: "idp.EmailAddress";
  id: Uint8Array;
  trust: Trust;
  /**
   * If set, this adress is considered externally-managed. It can only be
   * changed through the synchronization mechanism.
   */
  external: boolean;
  localPart: string;
  domain: string;
  /**
   * Time at which a code sent to this E-Mail address was last used to
   * authenticate the user.
   */
  lastUse: Date | undefined;
}

export interface PhoneNumber {
  $type: "idp.PhoneNumber";
  id: Uint8Array;
  trust: Trust;
  /**
   * If set, this adress is considered externally-managed. It can only be
   * changed through the synchronization mechanism.
   */
  external: boolean;
}

export interface AuthBackchannelRequest {
  $type: "idp.AuthBackchannelRequest";
  code: number;
}

export interface AuthBackchannelResponse {
  $type: "idp.AuthBackchannelResponse";
  result: AuthBackchannelResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthBackchannelResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  WRONG_CODE = 2,
  ALREADY_USED = 3,
  RATE_LIMITED = 4,
  UNRECOGNIZED = -1,
}

/** Username-related */
export interface AuthUsernameRequest {
  $type: "idp.AuthUsernameRequest";
  username: string;
}

export interface AuthUsernameResponse {
  $type: "idp.AuthUsernameResponse";
  result: AuthUsernameResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthUsernameResponse_Result {
  /** INVALID - Should not normally show up. */
  INVALID = 0,
  /** SUCCESS - An identity has been found and is avilable. */
  SUCCESS = 1,
  /** NOT_FOUND - The user could not be associated with an identity. */
  NOT_FOUND = 2,
  /** DISABLED - The user is disabled and cannot be used to authenticate. */
  DISABLED = 3,
  /** POLICY_VIOLATION - Authentication cannot continue. */
  POLICY_VIOLATION = 4,
  UNRECOGNIZED = -1,
}

/** Kerberos-related */
export interface AuthKerberosRequest {
  $type: "idp.AuthKerberosRequest";
  token: string;
}

export interface AuthKerberosResponse {
  $type: "idp.AuthKerberosResponse";
  result: AuthKerberosResponse_Result;
  authContinue: AuthContinue | undefined;
}

export enum AuthKerberosResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  POLICY_VIOLATION = 2,
  UNRECOGNIZED = -1,
}

export interface AuthContext {
  $type: "idp.AuthContext";
  /** List of ACRs that are acceptable for this operation, in order of preference */
  authenticationContextClass: string[];
  /** Maximum authentication age in seconds */
  maxAgeSeconds: number;
}

/**
 * Returned as part of status if a username hint is available for a given
 * delegation protocol.
 */
export interface UsernameHint {
  $type: "idp.UsernameHint";
  username: string;
}

/** Start-related */
export interface AuthStartRequest {
  $type: "idp.AuthStartRequest";
  authCtx:
    | AuthContext
    | undefined;
  /**
   * An optional hint containing a username. Only used if no user sessions
   * exists yet.
   */
  usernameHint: string;
}

export interface AuthStartResponse {
  $type: "idp.AuthStartResponse";
  result: AuthStartResponse_Result;
  authContinue: AuthContinue | undefined;
  clientProfile: ClientProfile | undefined;
}

export enum AuthStartResponse_Result {
  INVALID = 0,
  SUCCESS = 1,
  POLICY_VIOLATION = 2,
  UNRECOGNIZED = -1,
}

/** Logout */
export interface LogoutRequest {
  $type: "idp.LogoutRequest";
}

export interface LogoutResponse {
  $type: "idp.LogoutResponse";
}

/** Audit */
export interface AuthenticationEvent {
  $type: "idp.AuthenticationEvent";
  mechanism: Mechanism;
  result: number;
  authenticatorId: Uint8Array;
}

export interface OAuthAuthorizeEvent {
  $type: "idp.OAuthAuthorizeEvent";
  clientId: string;
  scopes: string[];
  result: number;
}

export interface SAMLEvent {
  $type: "idp.SAMLEvent";
  clientId: string;
}

export interface AuditEvent {
  $type: "idp.AuditEvent";
  timestamp: Date | undefined;
  sessionHash: Uint8Array;
  sub: string;
  event?: { $case: "authentication"; authentication: AuthenticationEvent } | undefined;
}

export interface RemoveTOTPAuthenticatorRequest {
  $type: "idp.RemoveTOTPAuthenticatorRequest";
  id: Uint8Array;
}

export interface RemoveTOTPAuthenticatorResponse {
  $type: "idp.RemoveTOTPAuthenticatorResponse";
}

export interface RemoveWebauthnRequest {
  $type: "idp.RemoveWebauthnRequest";
  id: Uint8Array;
}

export interface RemoveWebauthnResponse {
  $type: "idp.RemoveWebauthnResponse";
  id: Uint8Array;
}

export interface GetSelfRequest {
  $type: "idp.GetSelfRequest";
}

export interface GetSelfResponse {
  $type: "idp.GetSelfResponse";
  user: User | undefined;
}

export interface AuthImpersonateRequest {
  $type: "idp.AuthImpersonateRequest";
}

export interface AuthImpersonateResponse {
  $type: "idp.AuthImpersonateResponse";
}

export interface GetSessionsRequest {
  $type: "idp.GetSessionsRequest";
}

export interface GetSessionsResponse {
  $type: "idp.GetSessionsResponse";
  session: UserProfile[];
  selectedUserSession: number;
}

export interface GetBrandingRequest {
  $type: "idp.GetBrandingRequest";
}

export interface VerifyMailRequest {
  $type: "idp.VerifyMailRequest";
  token: string;
}

export interface VerifyMailResponse {
  $type: "idp.VerifyMailResponse";
}

export interface SendVerifyMailRequest {
  $type: "idp.SendVerifyMailRequest";
  email: string;
}

export interface SendVerifyMailResponse {
  $type: "idp.SendVerifyMailResponse";
}

export interface RemoveMailRequest {
  $type: "idp.RemoveMailRequest";
  id: Uint8Array;
}

export interface RemoveMailResponse {
  $type: "idp.RemoveMailResponse";
}

export interface GetUserRequest {
  $type: "idp.GetUserRequest";
  id: string;
  email: string;
  legacyUsername: string;
}

export interface GetUserResponse {
  $type: "idp.GetUserResponse";
  user: User | undefined;
}

export interface GetSessionsForUserRequest {
  $type: "idp.GetSessionsForUserRequest";
  /**
   * ID of the user whose sessions should be returned. If left empty the
   * authenticated user is used. If the calling entity doesn't have the
   * idp.internal/admin role calls will be rejected with PermissionDenied if
   * the user_id is not either empty or set to the authenticated user.
   */
  userId: string;
}

export interface UserAgent {
  $type: "idp.UserAgent";
  brand: string;
  platformBrand: string;
  deviceModel: string;
  mobile: boolean;
}

export interface GetSessionsForUserResponse {
  $type: "idp.GetSessionsForUserResponse";
}

export interface WatchEnrolmentRequest {
  $type: "idp.WatchEnrolmentRequest";
  type: WatchEnrolmentRequest_Type;
}

export enum WatchEnrolmentRequest_Type {
  INVALID = 0,
  EMAIL = 1,
  UNRECOGNIZED = -1,
}

export interface WatchEnrolmentEvent {
  $type: "idp.WatchEnrolmentEvent";
  ok: boolean;
}

export interface ResetRiskRequest {
  $type: "idp.ResetRiskRequest";
}

export interface ResetRiskResponse {
  $type: "idp.ResetRiskResponse";
}

export interface DeleteUserRequest {
  $type: "idp.DeleteUserRequest";
  userId: string;
}

export interface DeleteUserResponse {
  $type: "idp.DeleteUserResponse";
}

export interface CreateUserRequest {
  $type: "idp.CreateUserRequest";
  user:
    | User
    | undefined;
  /** set init password; optional */
  newPassword: string;
}

export interface CreateUserResponse {
  $type: "idp.CreateUserResponse";
}

export interface NewUserPasswordRequest {
  $type: "idp.NewUserPasswordRequest";
  userId: string;
  generatedPasswordFamily: GeneratedPasswordFamily;
  forceChange: boolean;
}

export interface NewUserPasswordResponse {
  $type: "idp.NewUserPasswordResponse";
  newPassword: string;
}

export interface ImportUserPasswordRequest {
  $type: "idp.ImportUserPasswordRequest";
  userId: string;
  format?: { $case: "password"; password: Password } | { $case: "cryptString"; cryptString: string } | undefined;
}

export interface ImportUserPasswordResponse {
  $type: "idp.ImportUserPasswordResponse";
}

export interface ListUsersRequest {
  $type: "idp.ListUsersRequest";
  /** The maximum number of items to return. Capped server-side at 1000. */
  pageSize: number;
  /** The next_page_token returned from a previous List request, if any. */
  pageToken: string;
}

export interface ListUsersResponse {
  $type: "idp.ListUsersResponse";
  /** Users in the list. */
  user: User[];
  /**
   * Token to retrieve the next page of results, or empty if there are no more
   * results in the list.
   */
  nextPageToken: string;
}

export interface AddUserClaimRequest {
  $type: "idp.AddUserClaimRequest";
  /** The ID of the user the claim should be added to. */
  userId: string;
  /** The name of the new claim. */
  claimName: string;
  /** The value of the new claim. */
  claimValue: any | undefined;
}

export interface AddUserClaimResponse {
  $type: "idp.AddUserClaimResponse";
}

export interface DeleteUserClaimRequest {
  $type: "idp.DeleteUserClaimRequest";
  /** The ID of the user the claim should be deleted from. */
  userId: string;
  /** The name of the claim to be deleted. */
  claimName: string;
}

export interface DeleteUserClaimResponse {
  $type: "idp.DeleteUserClaimResponse";
}

export interface AddUserToGroupRequest {
  $type: "idp.AddUserToGroupRequest";
  /** The ID of the user the group should be added to. */
  userId: string;
  /** The name of the group to be added. */
  groupName: string;
}

export interface AddUserToGroupResponse {
  $type: "idp.AddUserToGroupResponse";
}

export interface CreateServiceAccountRequest {
  $type: "idp.CreateServiceAccountRequest";
  /** The identity of the service the service account is for. */
  identity: string;
}

export interface CreateServiceAccountResponse {
  $type: "idp.CreateServiceAccountResponse";
  /** The service account just created */
  serviceAccount: ServiceAccount | undefined;
}

export interface CreateBackupRequest {
  $type: "idp.CreateBackupRequest";
}

export interface BackupFile {
  $type: "idp.BackupFile";
  databaseFile: Uint8Array;
  generatedAt: Date | undefined;
}

export interface RestoreBackupResponse {
  $type: "idp.RestoreBackupResponse";
}

export interface SyncFullRequest {
  $type: "idp.SyncFullRequest";
  user: User[];
}

export interface SyncFullResponse {
  $type: "idp.SyncFullResponse";
}

/**
 * PasswordStrengthPolicy contains policies regarding the strength of users'
 * passwords. Each class has an upper bound, i.e. all scores from preceding
 * upper bound until the one of the given class are considered part of it.
 * For example if too_simple_upper_bound contains 24 and acceptable_upper_bound
 * 32 all scores from 24 (inclusive) up to 32 (exclusive) are considered
 * "acceptable".
 * Everything above good_upper_bound is considered "strong".
 */
export interface PasswordStrengthPolicy {
  $type: "idp.PasswordStrengthPolicy";
  tooSimpleUpperBound: number;
  acceptableUpperBound: number;
  goodUpperBound: number;
}

export interface PasswordStrengthRequest {
  $type: "idp.PasswordStrengthRequest";
  /**
   * ID of the user the password is for. If left empty the authenticated user is
   * used. If the calling entity doesn't have the idp.internal/pwinterop or
   * idp.internal/admin roles calls will be rejected with PermissionDenied if
   * the user_id is not either empty or set to the authenticated user.
   */
  userId: string;
  /** password is the password to analyze in plaintext */
  password: string;
}

export interface PasswordStrengthResponse {
  $type: "idp.PasswordStrengthResponse";
  /**
   * Set to true if the password is included in a database of breached database
   * hooked up to the IDP.
   */
  breached: boolean;
  /**
   * Estimated entropy of the given password in bits (i.e. log2). This does not
   * include entropy loss from breached passwords.
   */
  strengthBits: number;
  /**
   * Set to true if the password meets all password policies for the given user.
   * If this is not true subsequent calls to ChangePassword with the same
   * password will fail.
   */
  acceptable: boolean;
  /**
   * The password strength policy applied to the user. Can be used for more
   * accurate user feedback.
   */
  policy: PasswordStrengthPolicy | undefined;
}

export interface GeneratePasswordRequest {
  $type: "idp.GeneratePasswordRequest";
  count: number;
  family: GeneratedPasswordFamily;
}

export interface GeneratePasswordResponse {
  $type: "idp.GeneratePasswordResponse";
  password: string[];
}

export interface FrontendConfig {
  $type: "idp.FrontendConfig";
  identifier: string;
}

/**
 * A ServiceAccount is a self-contained data structure containing everything to
 * securely authenticate and connect to the IDP.
 */
export interface ServiceAccount {
  $type: "idp.ServiceAccount";
  /** gRPC endpoint of the IDP */
  idpEndpoint: string;
  /** CA certificate the IDP's certificate is signed by */
  caPem: string;
  /** Client certificate */
  clientCertPem: string;
  /** Key for the client certificate */
  clientKeyPem: string;
}

function createBaseClientProfile(): ClientProfile {
  return { $type: "idp.ClientProfile", displayName: "", logoUrl: "" };
}

export const ClientProfile = {
  $type: "idp.ClientProfile" as const,

  encode(message: ClientProfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displayName !== "") {
      writer.uint32(42).string(message.displayName);
    }
    if (message.logoUrl !== "") {
      writer.uint32(50).string(message.logoUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientProfile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ClientProfile>, I>>(base?: I): ClientProfile {
    return ClientProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientProfile>, I>>(object: I): ClientProfile {
    const message = createBaseClientProfile();
    message.displayName = object.displayName ?? "";
    message.logoUrl = object.logoUrl ?? "";
    return message;
  },
};

messageTypeRegistry.set(ClientProfile.$type, ClientProfile);

function createBaseUserProfile(): UserProfile {
  return {
    $type: "idp.UserProfile",
    name: "",
    givenName: "",
    familyName: "",
    middleName: "",
    nickname: "",
    preferredUsername: "",
    profile: "",
    picture: "",
    website: "",
    gender: "",
    birthdate: "",
    zoneinfo: "",
    locale: "",
    updatedAt: 0,
  };
}

export const UserProfile = {
  $type: "idp.UserProfile" as const,

  encode(message: UserProfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.givenName !== "") {
      writer.uint32(18).string(message.givenName);
    }
    if (message.familyName !== "") {
      writer.uint32(26).string(message.familyName);
    }
    if (message.middleName !== "") {
      writer.uint32(34).string(message.middleName);
    }
    if (message.nickname !== "") {
      writer.uint32(42).string(message.nickname);
    }
    if (message.preferredUsername !== "") {
      writer.uint32(50).string(message.preferredUsername);
    }
    if (message.profile !== "") {
      writer.uint32(58).string(message.profile);
    }
    if (message.picture !== "") {
      writer.uint32(66).string(message.picture);
    }
    if (message.website !== "") {
      writer.uint32(74).string(message.website);
    }
    if (message.gender !== "") {
      writer.uint32(82).string(message.gender);
    }
    if (message.birthdate !== "") {
      writer.uint32(90).string(message.birthdate);
    }
    if (message.zoneinfo !== "") {
      writer.uint32(98).string(message.zoneinfo);
    }
    if (message.locale !== "") {
      writer.uint32(106).string(message.locale);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(112).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProfile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.givenName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.familyName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.middleName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nickname = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.preferredUsername = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.profile = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.picture = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.website = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.birthdate = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.zoneinfo = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.locale = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UserProfile>, I>>(base?: I): UserProfile {
    return UserProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserProfile>, I>>(object: I): UserProfile {
    const message = createBaseUserProfile();
    message.name = object.name ?? "";
    message.givenName = object.givenName ?? "";
    message.familyName = object.familyName ?? "";
    message.middleName = object.middleName ?? "";
    message.nickname = object.nickname ?? "";
    message.preferredUsername = object.preferredUsername ?? "";
    message.profile = object.profile ?? "";
    message.picture = object.picture ?? "";
    message.website = object.website ?? "";
    message.gender = object.gender ?? "";
    message.birthdate = object.birthdate ?? "";
    message.zoneinfo = object.zoneinfo ?? "";
    message.locale = object.locale ?? "";
    message.updatedAt = object.updatedAt ?? 0;
    return message;
  },
};

messageTypeRegistry.set(UserProfile.$type, UserProfile);

function createBaseUser(): User {
  return {
    $type: "idp.User",
    id: "",
    external: false,
    status: 0,
    totpAuthenticator: [],
    webauthnAuthenticator: [],
    emailAddress: [],
    phoneNumber: [],
    password: undefined,
    profile: undefined,
    memberOf: [],
    claim: {},
    ghostSince: undefined,
  };
}

export const User = {
  $type: "idp.User" as const,

  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(90).string(message.id);
    }
    if (message.external === true) {
      writer.uint32(96).bool(message.external);
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    for (const v of message.totpAuthenticator) {
      TOTPAuthenticator.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.webauthnAuthenticator) {
      WebauthnAuthenticator.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.emailAddress) {
      EmailAddress.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.phoneNumber) {
      PhoneNumber.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.password !== undefined) {
      Password.encode(message.password, writer.uint32(34).fork()).ldelim();
    }
    if (message.profile !== undefined) {
      UserProfile.encode(message.profile, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.memberOf) {
      writer.uint32(82).string(v!);
    }
    Object.entries(message.claim).forEach(([key, value]) => {
      if (value !== undefined) {
        User_ClaimEntry.encode({ $type: "idp.User.ClaimEntry", key: key as any, value }, writer.uint32(58).fork())
          .ldelim();
      }
    });
    if (message.ghostSince !== undefined) {
      Timestamp.encode(toTimestamp(message.ghostSince), writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 11:
          if (tag !== 90) {
            break;
          }

          message.id = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.external = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.totpAuthenticator.push(TOTPAuthenticator.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.webauthnAuthenticator.push(WebauthnAuthenticator.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.emailAddress.push(EmailAddress.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.phoneNumber.push(PhoneNumber.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = Password.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.profile = UserProfile.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.memberOf.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = User_ClaimEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.claim[entry7.key] = entry7.value;
          }
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.ghostSince = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? "";
    message.external = object.external ?? false;
    message.status = object.status ?? 0;
    message.totpAuthenticator = object.totpAuthenticator?.map((e) => TOTPAuthenticator.fromPartial(e)) || [];
    message.webauthnAuthenticator = object.webauthnAuthenticator?.map((e) => WebauthnAuthenticator.fromPartial(e)) ||
      [];
    message.emailAddress = object.emailAddress?.map((e) => EmailAddress.fromPartial(e)) || [];
    message.phoneNumber = object.phoneNumber?.map((e) => PhoneNumber.fromPartial(e)) || [];
    message.password = (object.password !== undefined && object.password !== null)
      ? Password.fromPartial(object.password)
      : undefined;
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? UserProfile.fromPartial(object.profile)
      : undefined;
    message.memberOf = object.memberOf?.map((e) => e) || [];
    message.claim = Object.entries(object.claim ?? {}).reduce<{ [key: string]: any | undefined }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );
    message.ghostSince = object.ghostSince ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(User.$type, User);

function createBaseUser_ClaimEntry(): User_ClaimEntry {
  return { $type: "idp.User.ClaimEntry", key: "", value: undefined };
}

export const User_ClaimEntry = {
  $type: "idp.User.ClaimEntry" as const,

  encode(message: User_ClaimEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_ClaimEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_ClaimEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<User_ClaimEntry>, I>>(base?: I): User_ClaimEntry {
    return User_ClaimEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User_ClaimEntry>, I>>(object: I): User_ClaimEntry {
    const message = createBaseUser_ClaimEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(User_ClaimEntry.$type, User_ClaimEntry);

function createBaseAuthContinue(): AuthContinue {
  return {
    $type: "idp.AuthContinue",
    prompt: "",
    nextMechanism: [],
    done: false,
    mechanismHint: 0,
    hasUsernames: false,
    enrollPassword: false,
    enrollTotp: false,
    totpAuthenticator: [],
    enrollWebauthn: false,
    webauthnAuthenticator: [],
    enrollEmail: false,
    emailAddress: [],
    emailEnrollment: undefined,
    nonce: new Uint8Array(0),
    newSessionToken: "",
    userProfile: undefined,
    userId: "",
  };
}

export const AuthContinue = {
  $type: "idp.AuthContinue" as const,

  encode(message: AuthContinue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prompt !== "") {
      writer.uint32(42).string(message.prompt);
    }
    writer.uint32(10).fork();
    for (const v of message.nextMechanism) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.done === true) {
      writer.uint32(48).bool(message.done);
    }
    if (message.mechanismHint !== 0) {
      writer.uint32(144).int32(message.mechanismHint);
    }
    if (message.hasUsernames === true) {
      writer.uint32(128).bool(message.hasUsernames);
    }
    if (message.enrollPassword === true) {
      writer.uint32(96).bool(message.enrollPassword);
    }
    if (message.enrollTotp === true) {
      writer.uint32(80).bool(message.enrollTotp);
    }
    for (const v of message.totpAuthenticator) {
      TOTPAuthenticator.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.enrollWebauthn === true) {
      writer.uint32(88).bool(message.enrollWebauthn);
    }
    for (const v of message.webauthnAuthenticator) {
      WebauthnAuthenticator.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.enrollEmail === true) {
      writer.uint32(112).bool(message.enrollEmail);
    }
    for (const v of message.emailAddress) {
      EmailAddress.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.emailEnrollment !== undefined) {
      EmailEnrollment.encode(message.emailEnrollment, writer.uint32(154).fork()).ldelim();
    }
    if (message.nonce.length !== 0) {
      writer.uint32(58).bytes(message.nonce);
    }
    if (message.newSessionToken !== "") {
      writer.uint32(18).string(message.newSessionToken);
    }
    if (message.userProfile !== undefined) {
      UserProfile.encode(message.userProfile, writer.uint32(26).fork()).ldelim();
    }
    if (message.userId !== "") {
      writer.uint32(34).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthContinue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthContinue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.prompt = reader.string();
          continue;
        case 1:
          if (tag === 8) {
            message.nextMechanism.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.nextMechanism.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.done = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.mechanismHint = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.hasUsernames = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.enrollPassword = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.enrollTotp = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.totpAuthenticator.push(TOTPAuthenticator.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.enrollWebauthn = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.webauthnAuthenticator.push(WebauthnAuthenticator.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.enrollEmail = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.emailAddress.push(EmailAddress.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.emailEnrollment = EmailEnrollment.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.nonce = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newSessionToken = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userProfile = UserProfile.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthContinue>, I>>(base?: I): AuthContinue {
    return AuthContinue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthContinue>, I>>(object: I): AuthContinue {
    const message = createBaseAuthContinue();
    message.prompt = object.prompt ?? "";
    message.nextMechanism = object.nextMechanism?.map((e) => e) || [];
    message.done = object.done ?? false;
    message.mechanismHint = object.mechanismHint ?? 0;
    message.hasUsernames = object.hasUsernames ?? false;
    message.enrollPassword = object.enrollPassword ?? false;
    message.enrollTotp = object.enrollTotp ?? false;
    message.totpAuthenticator = object.totpAuthenticator?.map((e) => TOTPAuthenticator.fromPartial(e)) || [];
    message.enrollWebauthn = object.enrollWebauthn ?? false;
    message.webauthnAuthenticator = object.webauthnAuthenticator?.map((e) => WebauthnAuthenticator.fromPartial(e)) ||
      [];
    message.enrollEmail = object.enrollEmail ?? false;
    message.emailAddress = object.emailAddress?.map((e) => EmailAddress.fromPartial(e)) || [];
    message.emailEnrollment = (object.emailEnrollment !== undefined && object.emailEnrollment !== null)
      ? EmailEnrollment.fromPartial(object.emailEnrollment)
      : undefined;
    message.nonce = object.nonce ?? new Uint8Array(0);
    message.newSessionToken = object.newSessionToken ?? "";
    message.userProfile = (object.userProfile !== undefined && object.userProfile !== null)
      ? UserProfile.fromPartial(object.userProfile)
      : undefined;
    message.userId = object.userId ?? "";
    return message;
  },
};

messageTypeRegistry.set(AuthContinue.$type, AuthContinue);

function createBaseEmailEnrollment(): EmailEnrollment {
  return { $type: "idp.EmailEnrollment", required: false, domains: [], notDomains: [] };
}

export const EmailEnrollment = {
  $type: "idp.EmailEnrollment" as const,

  encode(message: EmailEnrollment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.required === true) {
      writer.uint32(8).bool(message.required);
    }
    for (const v of message.domains) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.notDomains) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailEnrollment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailEnrollment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.required = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.domains.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.notDomains.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EmailEnrollment>, I>>(base?: I): EmailEnrollment {
    return EmailEnrollment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmailEnrollment>, I>>(object: I): EmailEnrollment {
    const message = createBaseEmailEnrollment();
    message.required = object.required ?? false;
    message.domains = object.domains?.map((e) => e) || [];
    message.notDomains = object.notDomains?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(EmailEnrollment.$type, EmailEnrollment);

function createBaseBranding(): Branding {
  return {
    $type: "idp.Branding",
    brand: "",
    name: "",
    logoFilename: "",
    logoStyle: 0,
    background: "",
    buttonColor: "",
    supportContactText: "",
    accessDeniedText: "",
    privacyPolicy: "",
  };
}

export const Branding = {
  $type: "idp.Branding" as const,

  encode(message: Branding, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brand !== "") {
      writer.uint32(10).string(message.brand);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.logoFilename !== "") {
      writer.uint32(26).string(message.logoFilename);
    }
    if (message.logoStyle !== 0) {
      writer.uint32(64).int32(message.logoStyle);
    }
    if (message.background !== "") {
      writer.uint32(50).string(message.background);
    }
    if (message.buttonColor !== "") {
      writer.uint32(58).string(message.buttonColor);
    }
    if (message.supportContactText !== "") {
      writer.uint32(34).string(message.supportContactText);
    }
    if (message.accessDeniedText !== "") {
      writer.uint32(42).string(message.accessDeniedText);
    }
    if (message.privacyPolicy !== "") {
      writer.uint32(74).string(message.privacyPolicy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Branding {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBranding();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brand = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.logoFilename = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.logoStyle = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.background = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.buttonColor = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.supportContactText = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accessDeniedText = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.privacyPolicy = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Branding>, I>>(base?: I): Branding {
    return Branding.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Branding>, I>>(object: I): Branding {
    const message = createBaseBranding();
    message.brand = object.brand ?? "";
    message.name = object.name ?? "";
    message.logoFilename = object.logoFilename ?? "";
    message.logoStyle = object.logoStyle ?? 0;
    message.background = object.background ?? "";
    message.buttonColor = object.buttonColor ?? "";
    message.supportContactText = object.supportContactText ?? "";
    message.accessDeniedText = object.accessDeniedText ?? "";
    message.privacyPolicy = object.privacyPolicy ?? "";
    return message;
  },
};

messageTypeRegistry.set(Branding.$type, Branding);

function createBaseAuthPasswordRequest(): AuthPasswordRequest {
  return { $type: "idp.AuthPasswordRequest", authCtx: undefined, password: "" };
}

export const AuthPasswordRequest = {
  $type: "idp.AuthPasswordRequest" as const,

  encode(message: AuthPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(18).fork()).ldelim();
    }
    if (message.password !== "") {
      writer.uint32(10).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authCtx = AuthContext.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthPasswordRequest>, I>>(base?: I): AuthPasswordRequest {
    return AuthPasswordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthPasswordRequest>, I>>(object: I): AuthPasswordRequest {
    const message = createBaseAuthPasswordRequest();
    message.authCtx = (object.authCtx !== undefined && object.authCtx !== null)
      ? AuthContext.fromPartial(object.authCtx)
      : undefined;
    message.password = object.password ?? "";
    return message;
  },
};

messageTypeRegistry.set(AuthPasswordRequest.$type, AuthPasswordRequest);

function createBaseAuthPasswordResponse(): AuthPasswordResponse {
  return { $type: "idp.AuthPasswordResponse", result: 0, authContinue: undefined };
}

export const AuthPasswordResponse = {
  $type: "idp.AuthPasswordResponse" as const,

  encode(message: AuthPasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthPasswordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthPasswordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthPasswordResponse>, I>>(base?: I): AuthPasswordResponse {
    return AuthPasswordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthPasswordResponse>, I>>(object: I): AuthPasswordResponse {
    const message = createBaseAuthPasswordResponse();
    message.result = object.result ?? 0;
    message.authContinue = (object.authContinue !== undefined && object.authContinue !== null)
      ? AuthContinue.fromPartial(object.authContinue)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthPasswordResponse.$type, AuthPasswordResponse);

function createBaseChangePasswordRequest(): ChangePasswordRequest {
  return { $type: "idp.ChangePasswordRequest", userId: "", fromPwinterop: false, oldPassword: "", newPassword: "" };
}

export const ChangePasswordRequest = {
  $type: "idp.ChangePasswordRequest" as const,

  encode(message: ChangePasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.fromPwinterop === true) {
      writer.uint32(40).bool(message.fromPwinterop);
    }
    if (message.oldPassword !== "") {
      writer.uint32(10).string(message.oldPassword);
    }
    if (message.newPassword !== "") {
      writer.uint32(18).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangePasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangePasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fromPwinterop = reader.bool();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.oldPassword = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ChangePasswordRequest>, I>>(base?: I): ChangePasswordRequest {
    return ChangePasswordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChangePasswordRequest>, I>>(object: I): ChangePasswordRequest {
    const message = createBaseChangePasswordRequest();
    message.userId = object.userId ?? "";
    message.fromPwinterop = object.fromPwinterop ?? false;
    message.oldPassword = object.oldPassword ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

messageTypeRegistry.set(ChangePasswordRequest.$type, ChangePasswordRequest);

function createBaseChangePasswordResponse(): ChangePasswordResponse {
  return { $type: "idp.ChangePasswordResponse" };
}

export const ChangePasswordResponse = {
  $type: "idp.ChangePasswordResponse" as const,

  encode(_: ChangePasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangePasswordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangePasswordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ChangePasswordResponse>, I>>(base?: I): ChangePasswordResponse {
    return ChangePasswordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChangePasswordResponse>, I>>(_: I): ChangePasswordResponse {
    const message = createBaseChangePasswordResponse();
    return message;
  },
};

messageTypeRegistry.set(ChangePasswordResponse.$type, ChangePasswordResponse);

function createBasePassword(): Password {
  return {
    $type: "idp.Password",
    algorithm: 0,
    derivedKey: new Uint8Array(0),
    salt: new Uint8Array(0),
    timeComplexity: 0,
    memoryComplexity: 0,
    parallelism: 0,
    estimatedEntropyBits: 0,
    breached: false,
    lastChanged: undefined,
    lastUse: undefined,
    forceChange: false,
  };
}

export const Password = {
  $type: "idp.Password" as const,

  encode(message: Password, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.algorithm !== 0) {
      writer.uint32(8).int32(message.algorithm);
    }
    if (message.derivedKey.length !== 0) {
      writer.uint32(18).bytes(message.derivedKey);
    }
    if (message.salt.length !== 0) {
      writer.uint32(26).bytes(message.salt);
    }
    if (message.timeComplexity !== 0) {
      writer.uint32(32).uint32(message.timeComplexity);
    }
    if (message.memoryComplexity !== 0) {
      writer.uint32(40).uint32(message.memoryComplexity);
    }
    if (message.parallelism !== 0) {
      writer.uint32(80).uint32(message.parallelism);
    }
    if (message.estimatedEntropyBits !== 0) {
      writer.uint32(49).double(message.estimatedEntropyBits);
    }
    if (message.breached === true) {
      writer.uint32(72).bool(message.breached);
    }
    if (message.lastChanged !== undefined) {
      Timestamp.encode(toTimestamp(message.lastChanged), writer.uint32(58).fork()).ldelim();
    }
    if (message.lastUse !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUse), writer.uint32(90).fork()).ldelim();
    }
    if (message.forceChange === true) {
      writer.uint32(64).bool(message.forceChange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Password {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePassword();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.algorithm = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.derivedKey = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.salt = reader.bytes();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timeComplexity = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.memoryComplexity = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.parallelism = reader.uint32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.estimatedEntropyBits = reader.double();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.breached = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastChanged = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lastUse = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.forceChange = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Password>, I>>(base?: I): Password {
    return Password.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Password>, I>>(object: I): Password {
    const message = createBasePassword();
    message.algorithm = object.algorithm ?? 0;
    message.derivedKey = object.derivedKey ?? new Uint8Array(0);
    message.salt = object.salt ?? new Uint8Array(0);
    message.timeComplexity = object.timeComplexity ?? 0;
    message.memoryComplexity = object.memoryComplexity ?? 0;
    message.parallelism = object.parallelism ?? 0;
    message.estimatedEntropyBits = object.estimatedEntropyBits ?? 0;
    message.breached = object.breached ?? false;
    message.lastChanged = object.lastChanged ?? undefined;
    message.lastUse = object.lastUse ?? undefined;
    message.forceChange = object.forceChange ?? false;
    return message;
  },
};

messageTypeRegistry.set(Password.$type, Password);

function createBaseSecretRisk(): SecretRisk {
  return { $type: "idp.SecretRisk", lastUpdated: undefined, tokenBucket: 0, lockedOut: false };
}

export const SecretRisk = {
  $type: "idp.SecretRisk" as const,

  encode(message: SecretRisk, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lastUpdated !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdated), writer.uint32(18).fork()).ldelim();
    }
    if (message.tokenBucket !== 0) {
      writer.uint32(25).double(message.tokenBucket);
    }
    if (message.lockedOut === true) {
      writer.uint32(32).bool(message.lockedOut);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretRisk {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretRisk();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastUpdated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.tokenBucket = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lockedOut = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SecretRisk>, I>>(base?: I): SecretRisk {
    return SecretRisk.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SecretRisk>, I>>(object: I): SecretRisk {
    const message = createBaseSecretRisk();
    message.lastUpdated = object.lastUpdated ?? undefined;
    message.tokenBucket = object.tokenBucket ?? 0;
    message.lockedOut = object.lockedOut ?? false;
    return message;
  },
};

messageTypeRegistry.set(SecretRisk.$type, SecretRisk);

function createBaseTOTPAuthenticator(): TOTPAuthenticator {
  return {
    $type: "idp.TOTPAuthenticator",
    id: new Uint8Array(0),
    name: "",
    digits: 0,
    stepDuration: 0,
    hash: 0,
    secret: new Uint8Array(0),
    lastUse: undefined,
  };
}

export const TOTPAuthenticator = {
  $type: "idp.TOTPAuthenticator" as const,

  encode(message: TOTPAuthenticator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.digits !== 0) {
      writer.uint32(32).uint32(message.digits);
    }
    if (message.stepDuration !== 0) {
      writer.uint32(40).uint32(message.stepDuration);
    }
    if (message.hash !== 0) {
      writer.uint32(48).int32(message.hash);
    }
    if (message.secret.length !== 0) {
      writer.uint32(26).bytes(message.secret);
    }
    if (message.lastUse !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUse), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TOTPAuthenticator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTOTPAuthenticator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.digits = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.stepDuration = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.hash = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secret = reader.bytes();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastUse = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TOTPAuthenticator>, I>>(base?: I): TOTPAuthenticator {
    return TOTPAuthenticator.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TOTPAuthenticator>, I>>(object: I): TOTPAuthenticator {
    const message = createBaseTOTPAuthenticator();
    message.id = object.id ?? new Uint8Array(0);
    message.name = object.name ?? "";
    message.digits = object.digits ?? 0;
    message.stepDuration = object.stepDuration ?? 0;
    message.hash = object.hash ?? 0;
    message.secret = object.secret ?? new Uint8Array(0);
    message.lastUse = object.lastUse ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(TOTPAuthenticator.$type, TOTPAuthenticator);

function createBaseAuthTOTPRequest(): AuthTOTPRequest {
  return { $type: "idp.AuthTOTPRequest", authCtx: undefined, authenticatorId: new Uint8Array(0), code: 0 };
}

export const AuthTOTPRequest = {
  $type: "idp.AuthTOTPRequest" as const,

  encode(message: AuthTOTPRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(26).fork()).ldelim();
    }
    if (message.authenticatorId.length !== 0) {
      writer.uint32(10).bytes(message.authenticatorId);
    }
    if (message.code !== 0) {
      writer.uint32(16).uint32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthTOTPRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthTOTPRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.authCtx = AuthContext.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authenticatorId = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.code = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthTOTPRequest>, I>>(base?: I): AuthTOTPRequest {
    return AuthTOTPRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthTOTPRequest>, I>>(object: I): AuthTOTPRequest {
    const message = createBaseAuthTOTPRequest();
    message.authCtx = (object.authCtx !== undefined && object.authCtx !== null)
      ? AuthContext.fromPartial(object.authCtx)
      : undefined;
    message.authenticatorId = object.authenticatorId ?? new Uint8Array(0);
    message.code = object.code ?? 0;
    return message;
  },
};

messageTypeRegistry.set(AuthTOTPRequest.$type, AuthTOTPRequest);

function createBaseAuthTOTPResponse(): AuthTOTPResponse {
  return { $type: "idp.AuthTOTPResponse", result: 0, authContinue: undefined };
}

export const AuthTOTPResponse = {
  $type: "idp.AuthTOTPResponse" as const,

  encode(message: AuthTOTPResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthTOTPResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthTOTPResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthTOTPResponse>, I>>(base?: I): AuthTOTPResponse {
    return AuthTOTPResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthTOTPResponse>, I>>(object: I): AuthTOTPResponse {
    const message = createBaseAuthTOTPResponse();
    message.result = object.result ?? 0;
    message.authContinue = (object.authContinue !== undefined && object.authContinue !== null)
      ? AuthContinue.fromPartial(object.authContinue)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthTOTPResponse.$type, AuthTOTPResponse);

function createBaseAddTOTPAuthenticatorRequest(): AddTOTPAuthenticatorRequest {
  return { $type: "idp.AddTOTPAuthenticatorRequest", authenticator: undefined, code: 0 };
}

export const AddTOTPAuthenticatorRequest = {
  $type: "idp.AddTOTPAuthenticatorRequest" as const,

  encode(message: AddTOTPAuthenticatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authenticator !== undefined) {
      TOTPAuthenticator.encode(message.authenticator, writer.uint32(10).fork()).ldelim();
    }
    if (message.code !== 0) {
      writer.uint32(16).uint32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTOTPAuthenticatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddTOTPAuthenticatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authenticator = TOTPAuthenticator.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.code = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddTOTPAuthenticatorRequest>, I>>(base?: I): AddTOTPAuthenticatorRequest {
    return AddTOTPAuthenticatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddTOTPAuthenticatorRequest>, I>>(object: I): AddTOTPAuthenticatorRequest {
    const message = createBaseAddTOTPAuthenticatorRequest();
    message.authenticator = (object.authenticator !== undefined && object.authenticator !== null)
      ? TOTPAuthenticator.fromPartial(object.authenticator)
      : undefined;
    message.code = object.code ?? 0;
    return message;
  },
};

messageTypeRegistry.set(AddTOTPAuthenticatorRequest.$type, AddTOTPAuthenticatorRequest);

function createBaseAddTOTPAuthenticatorResponse(): AddTOTPAuthenticatorResponse {
  return { $type: "idp.AddTOTPAuthenticatorResponse" };
}

export const AddTOTPAuthenticatorResponse = {
  $type: "idp.AddTOTPAuthenticatorResponse" as const,

  encode(_: AddTOTPAuthenticatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddTOTPAuthenticatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddTOTPAuthenticatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddTOTPAuthenticatorResponse>, I>>(base?: I): AddTOTPAuthenticatorResponse {
    return AddTOTPAuthenticatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddTOTPAuthenticatorResponse>, I>>(_: I): AddTOTPAuthenticatorResponse {
    const message = createBaseAddTOTPAuthenticatorResponse();
    return message;
  },
};

messageTypeRegistry.set(AddTOTPAuthenticatorResponse.$type, AddTOTPAuthenticatorResponse);

function createBaseWebauthnAuthenticator(): WebauthnAuthenticator {
  return {
    $type: "idp.WebauthnAuthenticator",
    id: new Uint8Array(0),
    name: "",
    algorithm: 0,
    publicKey: undefined,
    attestationData: new Uint8Array(0),
    counter: 0,
    lastUse: undefined,
  };
}

export const WebauthnAuthenticator = {
  $type: "idp.WebauthnAuthenticator" as const,

  encode(message: WebauthnAuthenticator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.algorithm !== 0) {
      writer.uint32(24).int32(message.algorithm);
    }
    if (message.publicKey !== undefined) {
      PublicKey.encode(message.publicKey, writer.uint32(34).fork()).ldelim();
    }
    if (message.attestationData.length !== 0) {
      writer.uint32(50).bytes(message.attestationData);
    }
    if (message.counter !== 0) {
      writer.uint32(40).int64(message.counter);
    }
    if (message.lastUse !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUse), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebauthnAuthenticator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebauthnAuthenticator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.algorithm = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.publicKey = PublicKey.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.attestationData = reader.bytes();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.counter = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastUse = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<WebauthnAuthenticator>, I>>(base?: I): WebauthnAuthenticator {
    return WebauthnAuthenticator.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WebauthnAuthenticator>, I>>(object: I): WebauthnAuthenticator {
    const message = createBaseWebauthnAuthenticator();
    message.id = object.id ?? new Uint8Array(0);
    message.name = object.name ?? "";
    message.algorithm = object.algorithm ?? 0;
    message.publicKey = (object.publicKey !== undefined && object.publicKey !== null)
      ? PublicKey.fromPartial(object.publicKey)
      : undefined;
    message.attestationData = object.attestationData ?? new Uint8Array(0);
    message.counter = object.counter ?? 0;
    message.lastUse = object.lastUse ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(WebauthnAuthenticator.$type, WebauthnAuthenticator);

function createBaseAddWebauthnRequest(): AddWebauthnRequest {
  return {
    $type: "idp.AddWebauthnRequest",
    name: "",
    id: new Uint8Array(0),
    clientDataJson: new Uint8Array(0),
    attestationObject: new Uint8Array(0),
  };
}

export const AddWebauthnRequest = {
  $type: "idp.AddWebauthnRequest" as const,

  encode(message: AddWebauthnRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.id.length !== 0) {
      writer.uint32(18).bytes(message.id);
    }
    if (message.clientDataJson.length !== 0) {
      writer.uint32(26).bytes(message.clientDataJson);
    }
    if (message.attestationObject.length !== 0) {
      writer.uint32(34).bytes(message.attestationObject);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddWebauthnRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddWebauthnRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientDataJson = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.attestationObject = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddWebauthnRequest>, I>>(base?: I): AddWebauthnRequest {
    return AddWebauthnRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddWebauthnRequest>, I>>(object: I): AddWebauthnRequest {
    const message = createBaseAddWebauthnRequest();
    message.name = object.name ?? "";
    message.id = object.id ?? new Uint8Array(0);
    message.clientDataJson = object.clientDataJson ?? new Uint8Array(0);
    message.attestationObject = object.attestationObject ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(AddWebauthnRequest.$type, AddWebauthnRequest);

function createBaseAddWebauthnResponse(): AddWebauthnResponse {
  return { $type: "idp.AddWebauthnResponse" };
}

export const AddWebauthnResponse = {
  $type: "idp.AddWebauthnResponse" as const,

  encode(_: AddWebauthnResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddWebauthnResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddWebauthnResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddWebauthnResponse>, I>>(base?: I): AddWebauthnResponse {
    return AddWebauthnResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddWebauthnResponse>, I>>(_: I): AddWebauthnResponse {
    const message = createBaseAddWebauthnResponse();
    return message;
  },
};

messageTypeRegistry.set(AddWebauthnResponse.$type, AddWebauthnResponse);

function createBaseAuthWebauthnRequest(): AuthWebauthnRequest {
  return {
    $type: "idp.AuthWebauthnRequest",
    authCtx: undefined,
    id: new Uint8Array(0),
    clientDataJson: new Uint8Array(0),
    authenticatorData: new Uint8Array(0),
    signature: new Uint8Array(0),
    userHandle: new Uint8Array(0),
  };
}

export const AuthWebauthnRequest = {
  $type: "idp.AuthWebauthnRequest" as const,

  encode(message: AuthWebauthnRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(50).fork()).ldelim();
    }
    if (message.id.length !== 0) {
      writer.uint32(42).bytes(message.id);
    }
    if (message.clientDataJson.length !== 0) {
      writer.uint32(10).bytes(message.clientDataJson);
    }
    if (message.authenticatorData.length !== 0) {
      writer.uint32(18).bytes(message.authenticatorData);
    }
    if (message.signature.length !== 0) {
      writer.uint32(26).bytes(message.signature);
    }
    if (message.userHandle.length !== 0) {
      writer.uint32(34).bytes(message.userHandle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthWebauthnRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthWebauthnRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 50) {
            break;
          }

          message.authCtx = AuthContext.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientDataJson = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authenticatorData = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signature = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.userHandle = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthWebauthnRequest>, I>>(base?: I): AuthWebauthnRequest {
    return AuthWebauthnRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthWebauthnRequest>, I>>(object: I): AuthWebauthnRequest {
    const message = createBaseAuthWebauthnRequest();
    message.authCtx = (object.authCtx !== undefined && object.authCtx !== null)
      ? AuthContext.fromPartial(object.authCtx)
      : undefined;
    message.id = object.id ?? new Uint8Array(0);
    message.clientDataJson = object.clientDataJson ?? new Uint8Array(0);
    message.authenticatorData = object.authenticatorData ?? new Uint8Array(0);
    message.signature = object.signature ?? new Uint8Array(0);
    message.userHandle = object.userHandle ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(AuthWebauthnRequest.$type, AuthWebauthnRequest);

function createBaseAuthWebauthnResponse(): AuthWebauthnResponse {
  return { $type: "idp.AuthWebauthnResponse", result: 0, authContinue: undefined };
}

export const AuthWebauthnResponse = {
  $type: "idp.AuthWebauthnResponse" as const,

  encode(message: AuthWebauthnResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthWebauthnResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthWebauthnResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthWebauthnResponse>, I>>(base?: I): AuthWebauthnResponse {
    return AuthWebauthnResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthWebauthnResponse>, I>>(object: I): AuthWebauthnResponse {
    const message = createBaseAuthWebauthnResponse();
    message.result = object.result ?? 0;
    message.authContinue = (object.authContinue !== undefined && object.authContinue !== null)
      ? AuthContinue.fromPartial(object.authContinue)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthWebauthnResponse.$type, AuthWebauthnResponse);

function createBaseSendAuthMailRequest(): SendAuthMailRequest {
  return {
    $type: "idp.SendAuthMailRequest",
    authCtx: undefined,
    type: 0,
    mechanismToBeReset: 0,
    id: new Uint8Array(0),
  };
}

export const SendAuthMailRequest = {
  $type: "idp.SendAuthMailRequest" as const,

  encode(message: SendAuthMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.mechanismToBeReset !== 0) {
      writer.uint32(32).int32(message.mechanismToBeReset);
    }
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendAuthMailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendAuthMailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authCtx = AuthContext.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.mechanismToBeReset = reader.int32() as any;
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SendAuthMailRequest>, I>>(base?: I): SendAuthMailRequest {
    return SendAuthMailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendAuthMailRequest>, I>>(object: I): SendAuthMailRequest {
    const message = createBaseSendAuthMailRequest();
    message.authCtx = (object.authCtx !== undefined && object.authCtx !== null)
      ? AuthContext.fromPartial(object.authCtx)
      : undefined;
    message.type = object.type ?? 0;
    message.mechanismToBeReset = object.mechanismToBeReset ?? 0;
    message.id = object.id ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(SendAuthMailRequest.$type, SendAuthMailRequest);

function createBaseSendAuthMailResponse(): SendAuthMailResponse {
  return { $type: "idp.SendAuthMailResponse" };
}

export const SendAuthMailResponse = {
  $type: "idp.SendAuthMailResponse" as const,

  encode(_: SendAuthMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendAuthMailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendAuthMailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SendAuthMailResponse>, I>>(base?: I): SendAuthMailResponse {
    return SendAuthMailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendAuthMailResponse>, I>>(_: I): SendAuthMailResponse {
    const message = createBaseSendAuthMailResponse();
    return message;
  },
};

messageTypeRegistry.set(SendAuthMailResponse.$type, SendAuthMailResponse);

function createBaseEmailAddress(): EmailAddress {
  return {
    $type: "idp.EmailAddress",
    id: new Uint8Array(0),
    trust: 0,
    external: false,
    localPart: "",
    domain: "",
    lastUse: undefined,
  };
}

export const EmailAddress = {
  $type: "idp.EmailAddress" as const,

  encode(message: EmailAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.trust !== 0) {
      writer.uint32(32).int32(message.trust);
    }
    if (message.external === true) {
      writer.uint32(40).bool(message.external);
    }
    if (message.localPart !== "") {
      writer.uint32(18).string(message.localPart);
    }
    if (message.domain !== "") {
      writer.uint32(26).string(message.domain);
    }
    if (message.lastUse !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUse), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.trust = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.external = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.localPart = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.domain = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastUse = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EmailAddress>, I>>(base?: I): EmailAddress {
    return EmailAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmailAddress>, I>>(object: I): EmailAddress {
    const message = createBaseEmailAddress();
    message.id = object.id ?? new Uint8Array(0);
    message.trust = object.trust ?? 0;
    message.external = object.external ?? false;
    message.localPart = object.localPart ?? "";
    message.domain = object.domain ?? "";
    message.lastUse = object.lastUse ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(EmailAddress.$type, EmailAddress);

function createBasePhoneNumber(): PhoneNumber {
  return { $type: "idp.PhoneNumber", id: new Uint8Array(0), trust: 0, external: false };
}

export const PhoneNumber = {
  $type: "idp.PhoneNumber" as const,

  encode(message: PhoneNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.trust !== 0) {
      writer.uint32(16).int32(message.trust);
    }
    if (message.external === true) {
      writer.uint32(32).bool(message.external);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PhoneNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhoneNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.trust = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.external = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PhoneNumber>, I>>(base?: I): PhoneNumber {
    return PhoneNumber.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PhoneNumber>, I>>(object: I): PhoneNumber {
    const message = createBasePhoneNumber();
    message.id = object.id ?? new Uint8Array(0);
    message.trust = object.trust ?? 0;
    message.external = object.external ?? false;
    return message;
  },
};

messageTypeRegistry.set(PhoneNumber.$type, PhoneNumber);

function createBaseAuthBackchannelRequest(): AuthBackchannelRequest {
  return { $type: "idp.AuthBackchannelRequest", code: 0 };
}

export const AuthBackchannelRequest = {
  $type: "idp.AuthBackchannelRequest" as const,

  encode(message: AuthBackchannelRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).uint32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthBackchannelRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthBackchannelRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthBackchannelRequest>, I>>(base?: I): AuthBackchannelRequest {
    return AuthBackchannelRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthBackchannelRequest>, I>>(object: I): AuthBackchannelRequest {
    const message = createBaseAuthBackchannelRequest();
    message.code = object.code ?? 0;
    return message;
  },
};

messageTypeRegistry.set(AuthBackchannelRequest.$type, AuthBackchannelRequest);

function createBaseAuthBackchannelResponse(): AuthBackchannelResponse {
  return { $type: "idp.AuthBackchannelResponse", result: 0, authContinue: undefined };
}

export const AuthBackchannelResponse = {
  $type: "idp.AuthBackchannelResponse" as const,

  encode(message: AuthBackchannelResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthBackchannelResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthBackchannelResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthBackchannelResponse>, I>>(base?: I): AuthBackchannelResponse {
    return AuthBackchannelResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthBackchannelResponse>, I>>(object: I): AuthBackchannelResponse {
    const message = createBaseAuthBackchannelResponse();
    message.result = object.result ?? 0;
    message.authContinue = (object.authContinue !== undefined && object.authContinue !== null)
      ? AuthContinue.fromPartial(object.authContinue)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthBackchannelResponse.$type, AuthBackchannelResponse);

function createBaseAuthUsernameRequest(): AuthUsernameRequest {
  return { $type: "idp.AuthUsernameRequest", username: "" };
}

export const AuthUsernameRequest = {
  $type: "idp.AuthUsernameRequest" as const,

  encode(message: AuthUsernameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthUsernameRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthUsernameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthUsernameRequest>, I>>(base?: I): AuthUsernameRequest {
    return AuthUsernameRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthUsernameRequest>, I>>(object: I): AuthUsernameRequest {
    const message = createBaseAuthUsernameRequest();
    message.username = object.username ?? "";
    return message;
  },
};

messageTypeRegistry.set(AuthUsernameRequest.$type, AuthUsernameRequest);

function createBaseAuthUsernameResponse(): AuthUsernameResponse {
  return { $type: "idp.AuthUsernameResponse", result: 0, authContinue: undefined };
}

export const AuthUsernameResponse = {
  $type: "idp.AuthUsernameResponse" as const,

  encode(message: AuthUsernameResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthUsernameResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthUsernameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthUsernameResponse>, I>>(base?: I): AuthUsernameResponse {
    return AuthUsernameResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthUsernameResponse>, I>>(object: I): AuthUsernameResponse {
    const message = createBaseAuthUsernameResponse();
    message.result = object.result ?? 0;
    message.authContinue = (object.authContinue !== undefined && object.authContinue !== null)
      ? AuthContinue.fromPartial(object.authContinue)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthUsernameResponse.$type, AuthUsernameResponse);

function createBaseAuthKerberosRequest(): AuthKerberosRequest {
  return { $type: "idp.AuthKerberosRequest", token: "" };
}

export const AuthKerberosRequest = {
  $type: "idp.AuthKerberosRequest" as const,

  encode(message: AuthKerberosRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthKerberosRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthKerberosRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthKerberosRequest>, I>>(base?: I): AuthKerberosRequest {
    return AuthKerberosRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthKerberosRequest>, I>>(object: I): AuthKerberosRequest {
    const message = createBaseAuthKerberosRequest();
    message.token = object.token ?? "";
    return message;
  },
};

messageTypeRegistry.set(AuthKerberosRequest.$type, AuthKerberosRequest);

function createBaseAuthKerberosResponse(): AuthKerberosResponse {
  return { $type: "idp.AuthKerberosResponse", result: 0, authContinue: undefined };
}

export const AuthKerberosResponse = {
  $type: "idp.AuthKerberosResponse" as const,

  encode(message: AuthKerberosResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthKerberosResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthKerberosResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthKerberosResponse>, I>>(base?: I): AuthKerberosResponse {
    return AuthKerberosResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthKerberosResponse>, I>>(object: I): AuthKerberosResponse {
    const message = createBaseAuthKerberosResponse();
    message.result = object.result ?? 0;
    message.authContinue = (object.authContinue !== undefined && object.authContinue !== null)
      ? AuthContinue.fromPartial(object.authContinue)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthKerberosResponse.$type, AuthKerberosResponse);

function createBaseAuthContext(): AuthContext {
  return { $type: "idp.AuthContext", authenticationContextClass: [], maxAgeSeconds: 0 };
}

export const AuthContext = {
  $type: "idp.AuthContext" as const,

  encode(message: AuthContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.authenticationContextClass) {
      writer.uint32(10).string(v!);
    }
    if (message.maxAgeSeconds !== 0) {
      writer.uint32(24).uint32(message.maxAgeSeconds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthContext {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthContext();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authenticationContextClass.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxAgeSeconds = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthContext>, I>>(base?: I): AuthContext {
    return AuthContext.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthContext>, I>>(object: I): AuthContext {
    const message = createBaseAuthContext();
    message.authenticationContextClass = object.authenticationContextClass?.map((e) => e) || [];
    message.maxAgeSeconds = object.maxAgeSeconds ?? 0;
    return message;
  },
};

messageTypeRegistry.set(AuthContext.$type, AuthContext);

function createBaseUsernameHint(): UsernameHint {
  return { $type: "idp.UsernameHint", username: "" };
}

export const UsernameHint = {
  $type: "idp.UsernameHint" as const,

  encode(message: UsernameHint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UsernameHint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsernameHint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UsernameHint>, I>>(base?: I): UsernameHint {
    return UsernameHint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UsernameHint>, I>>(object: I): UsernameHint {
    const message = createBaseUsernameHint();
    message.username = object.username ?? "";
    return message;
  },
};

messageTypeRegistry.set(UsernameHint.$type, UsernameHint);

function createBaseAuthStartRequest(): AuthStartRequest {
  return { $type: "idp.AuthStartRequest", authCtx: undefined, usernameHint: "" };
}

export const AuthStartRequest = {
  $type: "idp.AuthStartRequest" as const,

  encode(message: AuthStartRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authCtx !== undefined) {
      AuthContext.encode(message.authCtx, writer.uint32(10).fork()).ldelim();
    }
    if (message.usernameHint !== "") {
      writer.uint32(18).string(message.usernameHint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthStartRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthStartRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authCtx = AuthContext.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.usernameHint = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthStartRequest>, I>>(base?: I): AuthStartRequest {
    return AuthStartRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthStartRequest>, I>>(object: I): AuthStartRequest {
    const message = createBaseAuthStartRequest();
    message.authCtx = (object.authCtx !== undefined && object.authCtx !== null)
      ? AuthContext.fromPartial(object.authCtx)
      : undefined;
    message.usernameHint = object.usernameHint ?? "";
    return message;
  },
};

messageTypeRegistry.set(AuthStartRequest.$type, AuthStartRequest);

function createBaseAuthStartResponse(): AuthStartResponse {
  return { $type: "idp.AuthStartResponse", result: 0, authContinue: undefined, clientProfile: undefined };
}

export const AuthStartResponse = {
  $type: "idp.AuthStartResponse" as const,

  encode(message: AuthStartResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    if (message.authContinue !== undefined) {
      AuthContinue.encode(message.authContinue, writer.uint32(18).fork()).ldelim();
    }
    if (message.clientProfile !== undefined) {
      ClientProfile.encode(message.clientProfile, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthStartResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthStartResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.authContinue = AuthContinue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientProfile = ClientProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthStartResponse>, I>>(base?: I): AuthStartResponse {
    return AuthStartResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthStartResponse>, I>>(object: I): AuthStartResponse {
    const message = createBaseAuthStartResponse();
    message.result = object.result ?? 0;
    message.authContinue = (object.authContinue !== undefined && object.authContinue !== null)
      ? AuthContinue.fromPartial(object.authContinue)
      : undefined;
    message.clientProfile = (object.clientProfile !== undefined && object.clientProfile !== null)
      ? ClientProfile.fromPartial(object.clientProfile)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(AuthStartResponse.$type, AuthStartResponse);

function createBaseLogoutRequest(): LogoutRequest {
  return { $type: "idp.LogoutRequest" };
}

export const LogoutRequest = {
  $type: "idp.LogoutRequest" as const,

  encode(_: LogoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LogoutRequest>, I>>(base?: I): LogoutRequest {
    return LogoutRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogoutRequest>, I>>(_: I): LogoutRequest {
    const message = createBaseLogoutRequest();
    return message;
  },
};

messageTypeRegistry.set(LogoutRequest.$type, LogoutRequest);

function createBaseLogoutResponse(): LogoutResponse {
  return { $type: "idp.LogoutResponse" };
}

export const LogoutResponse = {
  $type: "idp.LogoutResponse" as const,

  encode(_: LogoutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LogoutResponse>, I>>(base?: I): LogoutResponse {
    return LogoutResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogoutResponse>, I>>(_: I): LogoutResponse {
    const message = createBaseLogoutResponse();
    return message;
  },
};

messageTypeRegistry.set(LogoutResponse.$type, LogoutResponse);

function createBaseAuthenticationEvent(): AuthenticationEvent {
  return { $type: "idp.AuthenticationEvent", mechanism: 0, result: 0, authenticatorId: new Uint8Array(0) };
}

export const AuthenticationEvent = {
  $type: "idp.AuthenticationEvent" as const,

  encode(message: AuthenticationEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mechanism !== 0) {
      writer.uint32(8).int32(message.mechanism);
    }
    if (message.result !== 0) {
      writer.uint32(16).uint32(message.result);
    }
    if (message.authenticatorId.length !== 0) {
      writer.uint32(26).bytes(message.authenticatorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthenticationEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthenticationEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mechanism = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.result = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.authenticatorId = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthenticationEvent>, I>>(base?: I): AuthenticationEvent {
    return AuthenticationEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthenticationEvent>, I>>(object: I): AuthenticationEvent {
    const message = createBaseAuthenticationEvent();
    message.mechanism = object.mechanism ?? 0;
    message.result = object.result ?? 0;
    message.authenticatorId = object.authenticatorId ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(AuthenticationEvent.$type, AuthenticationEvent);

function createBaseOAuthAuthorizeEvent(): OAuthAuthorizeEvent {
  return { $type: "idp.OAuthAuthorizeEvent", clientId: "", scopes: [], result: 0 };
}

export const OAuthAuthorizeEvent = {
  $type: "idp.OAuthAuthorizeEvent" as const,

  encode(message: OAuthAuthorizeEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    for (const v of message.scopes) {
      writer.uint32(18).string(v!);
    }
    if (message.result !== 0) {
      writer.uint32(24).uint32(message.result);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OAuthAuthorizeEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOAuthAuthorizeEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scopes.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.result = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<OAuthAuthorizeEvent>, I>>(base?: I): OAuthAuthorizeEvent {
    return OAuthAuthorizeEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OAuthAuthorizeEvent>, I>>(object: I): OAuthAuthorizeEvent {
    const message = createBaseOAuthAuthorizeEvent();
    message.clientId = object.clientId ?? "";
    message.scopes = object.scopes?.map((e) => e) || [];
    message.result = object.result ?? 0;
    return message;
  },
};

messageTypeRegistry.set(OAuthAuthorizeEvent.$type, OAuthAuthorizeEvent);

function createBaseSAMLEvent(): SAMLEvent {
  return { $type: "idp.SAMLEvent", clientId: "" };
}

export const SAMLEvent = {
  $type: "idp.SAMLEvent" as const,

  encode(message: SAMLEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SAMLEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSAMLEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SAMLEvent>, I>>(base?: I): SAMLEvent {
    return SAMLEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SAMLEvent>, I>>(object: I): SAMLEvent {
    const message = createBaseSAMLEvent();
    message.clientId = object.clientId ?? "";
    return message;
  },
};

messageTypeRegistry.set(SAMLEvent.$type, SAMLEvent);

function createBaseAuditEvent(): AuditEvent {
  return { $type: "idp.AuditEvent", timestamp: undefined, sessionHash: new Uint8Array(0), sub: "", event: undefined };
}

export const AuditEvent = {
  $type: "idp.AuditEvent" as const,

  encode(message: AuditEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.sessionHash.length !== 0) {
      writer.uint32(18).bytes(message.sessionHash);
    }
    if (message.sub !== "") {
      writer.uint32(26).string(message.sub);
    }
    switch (message.event?.$case) {
      case "authentication":
        AuthenticationEvent.encode(message.event.authentication, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuditEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuditEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sessionHash = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sub = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.event = {
            $case: "authentication",
            authentication: AuthenticationEvent.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuditEvent>, I>>(base?: I): AuditEvent {
    return AuditEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuditEvent>, I>>(object: I): AuditEvent {
    const message = createBaseAuditEvent();
    message.timestamp = object.timestamp ?? undefined;
    message.sessionHash = object.sessionHash ?? new Uint8Array(0);
    message.sub = object.sub ?? "";
    if (
      object.event?.$case === "authentication" &&
      object.event?.authentication !== undefined &&
      object.event?.authentication !== null
    ) {
      message.event = {
        $case: "authentication",
        authentication: AuthenticationEvent.fromPartial(object.event.authentication),
      };
    }
    return message;
  },
};

messageTypeRegistry.set(AuditEvent.$type, AuditEvent);

function createBaseRemoveTOTPAuthenticatorRequest(): RemoveTOTPAuthenticatorRequest {
  return { $type: "idp.RemoveTOTPAuthenticatorRequest", id: new Uint8Array(0) };
}

export const RemoveTOTPAuthenticatorRequest = {
  $type: "idp.RemoveTOTPAuthenticatorRequest" as const,

  encode(message: RemoveTOTPAuthenticatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveTOTPAuthenticatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveTOTPAuthenticatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveTOTPAuthenticatorRequest>, I>>(base?: I): RemoveTOTPAuthenticatorRequest {
    return RemoveTOTPAuthenticatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveTOTPAuthenticatorRequest>, I>>(
    object: I,
  ): RemoveTOTPAuthenticatorRequest {
    const message = createBaseRemoveTOTPAuthenticatorRequest();
    message.id = object.id ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(RemoveTOTPAuthenticatorRequest.$type, RemoveTOTPAuthenticatorRequest);

function createBaseRemoveTOTPAuthenticatorResponse(): RemoveTOTPAuthenticatorResponse {
  return { $type: "idp.RemoveTOTPAuthenticatorResponse" };
}

export const RemoveTOTPAuthenticatorResponse = {
  $type: "idp.RemoveTOTPAuthenticatorResponse" as const,

  encode(_: RemoveTOTPAuthenticatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveTOTPAuthenticatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveTOTPAuthenticatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveTOTPAuthenticatorResponse>, I>>(base?: I): RemoveTOTPAuthenticatorResponse {
    return RemoveTOTPAuthenticatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveTOTPAuthenticatorResponse>, I>>(_: I): RemoveTOTPAuthenticatorResponse {
    const message = createBaseRemoveTOTPAuthenticatorResponse();
    return message;
  },
};

messageTypeRegistry.set(RemoveTOTPAuthenticatorResponse.$type, RemoveTOTPAuthenticatorResponse);

function createBaseRemoveWebauthnRequest(): RemoveWebauthnRequest {
  return { $type: "idp.RemoveWebauthnRequest", id: new Uint8Array(0) };
}

export const RemoveWebauthnRequest = {
  $type: "idp.RemoveWebauthnRequest" as const,

  encode(message: RemoveWebauthnRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveWebauthnRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveWebauthnRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveWebauthnRequest>, I>>(base?: I): RemoveWebauthnRequest {
    return RemoveWebauthnRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveWebauthnRequest>, I>>(object: I): RemoveWebauthnRequest {
    const message = createBaseRemoveWebauthnRequest();
    message.id = object.id ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(RemoveWebauthnRequest.$type, RemoveWebauthnRequest);

function createBaseRemoveWebauthnResponse(): RemoveWebauthnResponse {
  return { $type: "idp.RemoveWebauthnResponse", id: new Uint8Array(0) };
}

export const RemoveWebauthnResponse = {
  $type: "idp.RemoveWebauthnResponse" as const,

  encode(message: RemoveWebauthnResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveWebauthnResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveWebauthnResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveWebauthnResponse>, I>>(base?: I): RemoveWebauthnResponse {
    return RemoveWebauthnResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveWebauthnResponse>, I>>(object: I): RemoveWebauthnResponse {
    const message = createBaseRemoveWebauthnResponse();
    message.id = object.id ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(RemoveWebauthnResponse.$type, RemoveWebauthnResponse);

function createBaseGetSelfRequest(): GetSelfRequest {
  return { $type: "idp.GetSelfRequest" };
}

export const GetSelfRequest = {
  $type: "idp.GetSelfRequest" as const,

  encode(_: GetSelfRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSelfRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSelfRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSelfRequest>, I>>(base?: I): GetSelfRequest {
    return GetSelfRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSelfRequest>, I>>(_: I): GetSelfRequest {
    const message = createBaseGetSelfRequest();
    return message;
  },
};

messageTypeRegistry.set(GetSelfRequest.$type, GetSelfRequest);

function createBaseGetSelfResponse(): GetSelfResponse {
  return { $type: "idp.GetSelfResponse", user: undefined };
}

export const GetSelfResponse = {
  $type: "idp.GetSelfResponse" as const,

  encode(message: GetSelfResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSelfResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSelfResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSelfResponse>, I>>(base?: I): GetSelfResponse {
    return GetSelfResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSelfResponse>, I>>(object: I): GetSelfResponse {
    const message = createBaseGetSelfResponse();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetSelfResponse.$type, GetSelfResponse);

function createBaseAuthImpersonateRequest(): AuthImpersonateRequest {
  return { $type: "idp.AuthImpersonateRequest" };
}

export const AuthImpersonateRequest = {
  $type: "idp.AuthImpersonateRequest" as const,

  encode(_: AuthImpersonateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthImpersonateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthImpersonateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthImpersonateRequest>, I>>(base?: I): AuthImpersonateRequest {
    return AuthImpersonateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthImpersonateRequest>, I>>(_: I): AuthImpersonateRequest {
    const message = createBaseAuthImpersonateRequest();
    return message;
  },
};

messageTypeRegistry.set(AuthImpersonateRequest.$type, AuthImpersonateRequest);

function createBaseAuthImpersonateResponse(): AuthImpersonateResponse {
  return { $type: "idp.AuthImpersonateResponse" };
}

export const AuthImpersonateResponse = {
  $type: "idp.AuthImpersonateResponse" as const,

  encode(_: AuthImpersonateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthImpersonateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthImpersonateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthImpersonateResponse>, I>>(base?: I): AuthImpersonateResponse {
    return AuthImpersonateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthImpersonateResponse>, I>>(_: I): AuthImpersonateResponse {
    const message = createBaseAuthImpersonateResponse();
    return message;
  },
};

messageTypeRegistry.set(AuthImpersonateResponse.$type, AuthImpersonateResponse);

function createBaseGetSessionsRequest(): GetSessionsRequest {
  return { $type: "idp.GetSessionsRequest" };
}

export const GetSessionsRequest = {
  $type: "idp.GetSessionsRequest" as const,

  encode(_: GetSessionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSessionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSessionsRequest>, I>>(base?: I): GetSessionsRequest {
    return GetSessionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSessionsRequest>, I>>(_: I): GetSessionsRequest {
    const message = createBaseGetSessionsRequest();
    return message;
  },
};

messageTypeRegistry.set(GetSessionsRequest.$type, GetSessionsRequest);

function createBaseGetSessionsResponse(): GetSessionsResponse {
  return { $type: "idp.GetSessionsResponse", session: [], selectedUserSession: 0 };
}

export const GetSessionsResponse = {
  $type: "idp.GetSessionsResponse" as const,

  encode(message: GetSessionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.session) {
      UserProfile.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.selectedUserSession !== 0) {
      writer.uint32(16).int32(message.selectedUserSession);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSessionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.session.push(UserProfile.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.selectedUserSession = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSessionsResponse>, I>>(base?: I): GetSessionsResponse {
    return GetSessionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSessionsResponse>, I>>(object: I): GetSessionsResponse {
    const message = createBaseGetSessionsResponse();
    message.session = object.session?.map((e) => UserProfile.fromPartial(e)) || [];
    message.selectedUserSession = object.selectedUserSession ?? 0;
    return message;
  },
};

messageTypeRegistry.set(GetSessionsResponse.$type, GetSessionsResponse);

function createBaseGetBrandingRequest(): GetBrandingRequest {
  return { $type: "idp.GetBrandingRequest" };
}

export const GetBrandingRequest = {
  $type: "idp.GetBrandingRequest" as const,

  encode(_: GetBrandingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBrandingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBrandingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetBrandingRequest>, I>>(base?: I): GetBrandingRequest {
    return GetBrandingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBrandingRequest>, I>>(_: I): GetBrandingRequest {
    const message = createBaseGetBrandingRequest();
    return message;
  },
};

messageTypeRegistry.set(GetBrandingRequest.$type, GetBrandingRequest);

function createBaseVerifyMailRequest(): VerifyMailRequest {
  return { $type: "idp.VerifyMailRequest", token: "" };
}

export const VerifyMailRequest = {
  $type: "idp.VerifyMailRequest" as const,

  encode(message: VerifyMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyMailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyMailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<VerifyMailRequest>, I>>(base?: I): VerifyMailRequest {
    return VerifyMailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VerifyMailRequest>, I>>(object: I): VerifyMailRequest {
    const message = createBaseVerifyMailRequest();
    message.token = object.token ?? "";
    return message;
  },
};

messageTypeRegistry.set(VerifyMailRequest.$type, VerifyMailRequest);

function createBaseVerifyMailResponse(): VerifyMailResponse {
  return { $type: "idp.VerifyMailResponse" };
}

export const VerifyMailResponse = {
  $type: "idp.VerifyMailResponse" as const,

  encode(_: VerifyMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyMailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyMailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<VerifyMailResponse>, I>>(base?: I): VerifyMailResponse {
    return VerifyMailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VerifyMailResponse>, I>>(_: I): VerifyMailResponse {
    const message = createBaseVerifyMailResponse();
    return message;
  },
};

messageTypeRegistry.set(VerifyMailResponse.$type, VerifyMailResponse);

function createBaseSendVerifyMailRequest(): SendVerifyMailRequest {
  return { $type: "idp.SendVerifyMailRequest", email: "" };
}

export const SendVerifyMailRequest = {
  $type: "idp.SendVerifyMailRequest" as const,

  encode(message: SendVerifyMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendVerifyMailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendVerifyMailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SendVerifyMailRequest>, I>>(base?: I): SendVerifyMailRequest {
    return SendVerifyMailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendVerifyMailRequest>, I>>(object: I): SendVerifyMailRequest {
    const message = createBaseSendVerifyMailRequest();
    message.email = object.email ?? "";
    return message;
  },
};

messageTypeRegistry.set(SendVerifyMailRequest.$type, SendVerifyMailRequest);

function createBaseSendVerifyMailResponse(): SendVerifyMailResponse {
  return { $type: "idp.SendVerifyMailResponse" };
}

export const SendVerifyMailResponse = {
  $type: "idp.SendVerifyMailResponse" as const,

  encode(_: SendVerifyMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendVerifyMailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendVerifyMailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SendVerifyMailResponse>, I>>(base?: I): SendVerifyMailResponse {
    return SendVerifyMailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SendVerifyMailResponse>, I>>(_: I): SendVerifyMailResponse {
    const message = createBaseSendVerifyMailResponse();
    return message;
  },
};

messageTypeRegistry.set(SendVerifyMailResponse.$type, SendVerifyMailResponse);

function createBaseRemoveMailRequest(): RemoveMailRequest {
  return { $type: "idp.RemoveMailRequest", id: new Uint8Array(0) };
}

export const RemoveMailRequest = {
  $type: "idp.RemoveMailRequest" as const,

  encode(message: RemoveMailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveMailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveMailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveMailRequest>, I>>(base?: I): RemoveMailRequest {
    return RemoveMailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveMailRequest>, I>>(object: I): RemoveMailRequest {
    const message = createBaseRemoveMailRequest();
    message.id = object.id ?? new Uint8Array(0);
    return message;
  },
};

messageTypeRegistry.set(RemoveMailRequest.$type, RemoveMailRequest);

function createBaseRemoveMailResponse(): RemoveMailResponse {
  return { $type: "idp.RemoveMailResponse" };
}

export const RemoveMailResponse = {
  $type: "idp.RemoveMailResponse" as const,

  encode(_: RemoveMailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveMailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveMailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveMailResponse>, I>>(base?: I): RemoveMailResponse {
    return RemoveMailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveMailResponse>, I>>(_: I): RemoveMailResponse {
    const message = createBaseRemoveMailResponse();
    return message;
  },
};

messageTypeRegistry.set(RemoveMailResponse.$type, RemoveMailResponse);

function createBaseGetUserRequest(): GetUserRequest {
  return { $type: "idp.GetUserRequest", id: "", email: "", legacyUsername: "" };
}

export const GetUserRequest = {
  $type: "idp.GetUserRequest" as const,

  encode(message: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.legacyUsername !== "") {
      writer.uint32(26).string(message.legacyUsername);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.legacyUsername = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(object: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    message.id = object.id ?? "";
    message.email = object.email ?? "";
    message.legacyUsername = object.legacyUsername ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetUserRequest.$type, GetUserRequest);

function createBaseGetUserResponse(): GetUserResponse {
  return { $type: "idp.GetUserResponse", user: undefined };
}

export const GetUserResponse = {
  $type: "idp.GetUserResponse" as const,

  encode(message: GetUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserResponse>, I>>(base?: I): GetUserResponse {
    return GetUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserResponse>, I>>(object: I): GetUserResponse {
    const message = createBaseGetUserResponse();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetUserResponse.$type, GetUserResponse);

function createBaseGetSessionsForUserRequest(): GetSessionsForUserRequest {
  return { $type: "idp.GetSessionsForUserRequest", userId: "" };
}

export const GetSessionsForUserRequest = {
  $type: "idp.GetSessionsForUserRequest" as const,

  encode(message: GetSessionsForUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsForUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSessionsForUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSessionsForUserRequest>, I>>(base?: I): GetSessionsForUserRequest {
    return GetSessionsForUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSessionsForUserRequest>, I>>(object: I): GetSessionsForUserRequest {
    const message = createBaseGetSessionsForUserRequest();
    message.userId = object.userId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetSessionsForUserRequest.$type, GetSessionsForUserRequest);

function createBaseUserAgent(): UserAgent {
  return { $type: "idp.UserAgent", brand: "", platformBrand: "", deviceModel: "", mobile: false };
}

export const UserAgent = {
  $type: "idp.UserAgent" as const,

  encode(message: UserAgent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brand !== "") {
      writer.uint32(10).string(message.brand);
    }
    if (message.platformBrand !== "") {
      writer.uint32(18).string(message.platformBrand);
    }
    if (message.deviceModel !== "") {
      writer.uint32(26).string(message.deviceModel);
    }
    if (message.mobile === true) {
      writer.uint32(32).bool(message.mobile);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAgent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAgent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brand = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.platformBrand = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceModel = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.mobile = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UserAgent>, I>>(base?: I): UserAgent {
    return UserAgent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserAgent>, I>>(object: I): UserAgent {
    const message = createBaseUserAgent();
    message.brand = object.brand ?? "";
    message.platformBrand = object.platformBrand ?? "";
    message.deviceModel = object.deviceModel ?? "";
    message.mobile = object.mobile ?? false;
    return message;
  },
};

messageTypeRegistry.set(UserAgent.$type, UserAgent);

function createBaseGetSessionsForUserResponse(): GetSessionsForUserResponse {
  return { $type: "idp.GetSessionsForUserResponse" };
}

export const GetSessionsForUserResponse = {
  $type: "idp.GetSessionsForUserResponse" as const,

  encode(_: GetSessionsForUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSessionsForUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSessionsForUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSessionsForUserResponse>, I>>(base?: I): GetSessionsForUserResponse {
    return GetSessionsForUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSessionsForUserResponse>, I>>(_: I): GetSessionsForUserResponse {
    const message = createBaseGetSessionsForUserResponse();
    return message;
  },
};

messageTypeRegistry.set(GetSessionsForUserResponse.$type, GetSessionsForUserResponse);

function createBaseWatchEnrolmentRequest(): WatchEnrolmentRequest {
  return { $type: "idp.WatchEnrolmentRequest", type: 0 };
}

export const WatchEnrolmentRequest = {
  $type: "idp.WatchEnrolmentRequest" as const,

  encode(message: WatchEnrolmentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WatchEnrolmentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWatchEnrolmentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<WatchEnrolmentRequest>, I>>(base?: I): WatchEnrolmentRequest {
    return WatchEnrolmentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WatchEnrolmentRequest>, I>>(object: I): WatchEnrolmentRequest {
    const message = createBaseWatchEnrolmentRequest();
    message.type = object.type ?? 0;
    return message;
  },
};

messageTypeRegistry.set(WatchEnrolmentRequest.$type, WatchEnrolmentRequest);

function createBaseWatchEnrolmentEvent(): WatchEnrolmentEvent {
  return { $type: "idp.WatchEnrolmentEvent", ok: false };
}

export const WatchEnrolmentEvent = {
  $type: "idp.WatchEnrolmentEvent" as const,

  encode(message: WatchEnrolmentEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ok === true) {
      writer.uint32(8).bool(message.ok);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WatchEnrolmentEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWatchEnrolmentEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ok = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<WatchEnrolmentEvent>, I>>(base?: I): WatchEnrolmentEvent {
    return WatchEnrolmentEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WatchEnrolmentEvent>, I>>(object: I): WatchEnrolmentEvent {
    const message = createBaseWatchEnrolmentEvent();
    message.ok = object.ok ?? false;
    return message;
  },
};

messageTypeRegistry.set(WatchEnrolmentEvent.$type, WatchEnrolmentEvent);

function createBaseResetRiskRequest(): ResetRiskRequest {
  return { $type: "idp.ResetRiskRequest" };
}

export const ResetRiskRequest = {
  $type: "idp.ResetRiskRequest" as const,

  encode(_: ResetRiskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetRiskRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetRiskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ResetRiskRequest>, I>>(base?: I): ResetRiskRequest {
    return ResetRiskRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetRiskRequest>, I>>(_: I): ResetRiskRequest {
    const message = createBaseResetRiskRequest();
    return message;
  },
};

messageTypeRegistry.set(ResetRiskRequest.$type, ResetRiskRequest);

function createBaseResetRiskResponse(): ResetRiskResponse {
  return { $type: "idp.ResetRiskResponse" };
}

export const ResetRiskResponse = {
  $type: "idp.ResetRiskResponse" as const,

  encode(_: ResetRiskResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetRiskResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetRiskResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ResetRiskResponse>, I>>(base?: I): ResetRiskResponse {
    return ResetRiskResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetRiskResponse>, I>>(_: I): ResetRiskResponse {
    const message = createBaseResetRiskResponse();
    return message;
  },
};

messageTypeRegistry.set(ResetRiskResponse.$type, ResetRiskResponse);

function createBaseDeleteUserRequest(): DeleteUserRequest {
  return { $type: "idp.DeleteUserRequest", userId: "" };
}

export const DeleteUserRequest = {
  $type: "idp.DeleteUserRequest" as const,

  encode(message: DeleteUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteUserRequest>, I>>(base?: I): DeleteUserRequest {
    return DeleteUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserRequest>, I>>(object: I): DeleteUserRequest {
    const message = createBaseDeleteUserRequest();
    message.userId = object.userId ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteUserRequest.$type, DeleteUserRequest);

function createBaseDeleteUserResponse(): DeleteUserResponse {
  return { $type: "idp.DeleteUserResponse" };
}

export const DeleteUserResponse = {
  $type: "idp.DeleteUserResponse" as const,

  encode(_: DeleteUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteUserResponse>, I>>(base?: I): DeleteUserResponse {
    return DeleteUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserResponse>, I>>(_: I): DeleteUserResponse {
    const message = createBaseDeleteUserResponse();
    return message;
  },
};

messageTypeRegistry.set(DeleteUserResponse.$type, DeleteUserResponse);

function createBaseCreateUserRequest(): CreateUserRequest {
  return { $type: "idp.CreateUserRequest", user: undefined, newPassword: "" };
}

export const CreateUserRequest = {
  $type: "idp.CreateUserRequest" as const,

  encode(message: CreateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.newPassword !== "") {
      writer.uint32(18).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateUserRequest>, I>>(base?: I): CreateUserRequest {
    return CreateUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserRequest>, I>>(object: I): CreateUserRequest {
    const message = createBaseCreateUserRequest();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

messageTypeRegistry.set(CreateUserRequest.$type, CreateUserRequest);

function createBaseCreateUserResponse(): CreateUserResponse {
  return { $type: "idp.CreateUserResponse" };
}

export const CreateUserResponse = {
  $type: "idp.CreateUserResponse" as const,

  encode(_: CreateUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateUserResponse>, I>>(base?: I): CreateUserResponse {
    return CreateUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserResponse>, I>>(_: I): CreateUserResponse {
    const message = createBaseCreateUserResponse();
    return message;
  },
};

messageTypeRegistry.set(CreateUserResponse.$type, CreateUserResponse);

function createBaseNewUserPasswordRequest(): NewUserPasswordRequest {
  return { $type: "idp.NewUserPasswordRequest", userId: "", generatedPasswordFamily: 0, forceChange: false };
}

export const NewUserPasswordRequest = {
  $type: "idp.NewUserPasswordRequest" as const,

  encode(message: NewUserPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.generatedPasswordFamily !== 0) {
      writer.uint32(16).int32(message.generatedPasswordFamily);
    }
    if (message.forceChange === true) {
      writer.uint32(24).bool(message.forceChange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewUserPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewUserPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.generatedPasswordFamily = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.forceChange = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<NewUserPasswordRequest>, I>>(base?: I): NewUserPasswordRequest {
    return NewUserPasswordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewUserPasswordRequest>, I>>(object: I): NewUserPasswordRequest {
    const message = createBaseNewUserPasswordRequest();
    message.userId = object.userId ?? "";
    message.generatedPasswordFamily = object.generatedPasswordFamily ?? 0;
    message.forceChange = object.forceChange ?? false;
    return message;
  },
};

messageTypeRegistry.set(NewUserPasswordRequest.$type, NewUserPasswordRequest);

function createBaseNewUserPasswordResponse(): NewUserPasswordResponse {
  return { $type: "idp.NewUserPasswordResponse", newPassword: "" };
}

export const NewUserPasswordResponse = {
  $type: "idp.NewUserPasswordResponse" as const,

  encode(message: NewUserPasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newPassword !== "") {
      writer.uint32(10).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewUserPasswordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewUserPasswordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<NewUserPasswordResponse>, I>>(base?: I): NewUserPasswordResponse {
    return NewUserPasswordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewUserPasswordResponse>, I>>(object: I): NewUserPasswordResponse {
    const message = createBaseNewUserPasswordResponse();
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

messageTypeRegistry.set(NewUserPasswordResponse.$type, NewUserPasswordResponse);

function createBaseImportUserPasswordRequest(): ImportUserPasswordRequest {
  return { $type: "idp.ImportUserPasswordRequest", userId: "", format: undefined };
}

export const ImportUserPasswordRequest = {
  $type: "idp.ImportUserPasswordRequest" as const,

  encode(message: ImportUserPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    switch (message.format?.$case) {
      case "password":
        Password.encode(message.format.password, writer.uint32(18).fork()).ldelim();
        break;
      case "cryptString":
        writer.uint32(26).string(message.format.cryptString);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImportUserPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportUserPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = { $case: "password", password: Password.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.format = { $case: "cryptString", cryptString: reader.string() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ImportUserPasswordRequest>, I>>(base?: I): ImportUserPasswordRequest {
    return ImportUserPasswordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImportUserPasswordRequest>, I>>(object: I): ImportUserPasswordRequest {
    const message = createBaseImportUserPasswordRequest();
    message.userId = object.userId ?? "";
    if (
      object.format?.$case === "password" && object.format?.password !== undefined && object.format?.password !== null
    ) {
      message.format = { $case: "password", password: Password.fromPartial(object.format.password) };
    }
    if (
      object.format?.$case === "cryptString" &&
      object.format?.cryptString !== undefined &&
      object.format?.cryptString !== null
    ) {
      message.format = { $case: "cryptString", cryptString: object.format.cryptString };
    }
    return message;
  },
};

messageTypeRegistry.set(ImportUserPasswordRequest.$type, ImportUserPasswordRequest);

function createBaseImportUserPasswordResponse(): ImportUserPasswordResponse {
  return { $type: "idp.ImportUserPasswordResponse" };
}

export const ImportUserPasswordResponse = {
  $type: "idp.ImportUserPasswordResponse" as const,

  encode(_: ImportUserPasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImportUserPasswordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportUserPasswordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ImportUserPasswordResponse>, I>>(base?: I): ImportUserPasswordResponse {
    return ImportUserPasswordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImportUserPasswordResponse>, I>>(_: I): ImportUserPasswordResponse {
    const message = createBaseImportUserPasswordResponse();
    return message;
  },
};

messageTypeRegistry.set(ImportUserPasswordResponse.$type, ImportUserPasswordResponse);

function createBaseListUsersRequest(): ListUsersRequest {
  return { $type: "idp.ListUsersRequest", pageSize: 0, pageToken: "" };
}

export const ListUsersRequest = {
  $type: "idp.ListUsersRequest" as const,

  encode(message: ListUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListUsersRequest>, I>>(base?: I): ListUsersRequest {
    return ListUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUsersRequest>, I>>(object: I): ListUsersRequest {
    const message = createBaseListUsersRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

messageTypeRegistry.set(ListUsersRequest.$type, ListUsersRequest);

function createBaseListUsersResponse(): ListUsersResponse {
  return { $type: "idp.ListUsersResponse", user: [], nextPageToken: "" };
}

export const ListUsersResponse = {
  $type: "idp.ListUsersResponse" as const,

  encode(message: ListUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.user) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user.push(User.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListUsersResponse>, I>>(base?: I): ListUsersResponse {
    return ListUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUsersResponse>, I>>(object: I): ListUsersResponse {
    const message = createBaseListUsersResponse();
    message.user = object.user?.map((e) => User.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

messageTypeRegistry.set(ListUsersResponse.$type, ListUsersResponse);

function createBaseAddUserClaimRequest(): AddUserClaimRequest {
  return { $type: "idp.AddUserClaimRequest", userId: "", claimName: "", claimValue: undefined };
}

export const AddUserClaimRequest = {
  $type: "idp.AddUserClaimRequest" as const,

  encode(message: AddUserClaimRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.claimName !== "") {
      writer.uint32(18).string(message.claimName);
    }
    if (message.claimValue !== undefined) {
      Value.encode(Value.wrap(message.claimValue), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserClaimRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddUserClaimRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.claimName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.claimValue = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddUserClaimRequest>, I>>(base?: I): AddUserClaimRequest {
    return AddUserClaimRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddUserClaimRequest>, I>>(object: I): AddUserClaimRequest {
    const message = createBaseAddUserClaimRequest();
    message.userId = object.userId ?? "";
    message.claimName = object.claimName ?? "";
    message.claimValue = object.claimValue ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(AddUserClaimRequest.$type, AddUserClaimRequest);

function createBaseAddUserClaimResponse(): AddUserClaimResponse {
  return { $type: "idp.AddUserClaimResponse" };
}

export const AddUserClaimResponse = {
  $type: "idp.AddUserClaimResponse" as const,

  encode(_: AddUserClaimResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserClaimResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddUserClaimResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddUserClaimResponse>, I>>(base?: I): AddUserClaimResponse {
    return AddUserClaimResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddUserClaimResponse>, I>>(_: I): AddUserClaimResponse {
    const message = createBaseAddUserClaimResponse();
    return message;
  },
};

messageTypeRegistry.set(AddUserClaimResponse.$type, AddUserClaimResponse);

function createBaseDeleteUserClaimRequest(): DeleteUserClaimRequest {
  return { $type: "idp.DeleteUserClaimRequest", userId: "", claimName: "" };
}

export const DeleteUserClaimRequest = {
  $type: "idp.DeleteUserClaimRequest" as const,

  encode(message: DeleteUserClaimRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.claimName !== "") {
      writer.uint32(18).string(message.claimName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserClaimRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserClaimRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.claimName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteUserClaimRequest>, I>>(base?: I): DeleteUserClaimRequest {
    return DeleteUserClaimRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserClaimRequest>, I>>(object: I): DeleteUserClaimRequest {
    const message = createBaseDeleteUserClaimRequest();
    message.userId = object.userId ?? "";
    message.claimName = object.claimName ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteUserClaimRequest.$type, DeleteUserClaimRequest);

function createBaseDeleteUserClaimResponse(): DeleteUserClaimResponse {
  return { $type: "idp.DeleteUserClaimResponse" };
}

export const DeleteUserClaimResponse = {
  $type: "idp.DeleteUserClaimResponse" as const,

  encode(_: DeleteUserClaimResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserClaimResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserClaimResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteUserClaimResponse>, I>>(base?: I): DeleteUserClaimResponse {
    return DeleteUserClaimResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteUserClaimResponse>, I>>(_: I): DeleteUserClaimResponse {
    const message = createBaseDeleteUserClaimResponse();
    return message;
  },
};

messageTypeRegistry.set(DeleteUserClaimResponse.$type, DeleteUserClaimResponse);

function createBaseAddUserToGroupRequest(): AddUserToGroupRequest {
  return { $type: "idp.AddUserToGroupRequest", userId: "", groupName: "" };
}

export const AddUserToGroupRequest = {
  $type: "idp.AddUserToGroupRequest" as const,

  encode(message: AddUserToGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.groupName !== "") {
      writer.uint32(18).string(message.groupName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserToGroupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddUserToGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddUserToGroupRequest>, I>>(base?: I): AddUserToGroupRequest {
    return AddUserToGroupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddUserToGroupRequest>, I>>(object: I): AddUserToGroupRequest {
    const message = createBaseAddUserToGroupRequest();
    message.userId = object.userId ?? "";
    message.groupName = object.groupName ?? "";
    return message;
  },
};

messageTypeRegistry.set(AddUserToGroupRequest.$type, AddUserToGroupRequest);

function createBaseAddUserToGroupResponse(): AddUserToGroupResponse {
  return { $type: "idp.AddUserToGroupResponse" };
}

export const AddUserToGroupResponse = {
  $type: "idp.AddUserToGroupResponse" as const,

  encode(_: AddUserToGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserToGroupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddUserToGroupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddUserToGroupResponse>, I>>(base?: I): AddUserToGroupResponse {
    return AddUserToGroupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddUserToGroupResponse>, I>>(_: I): AddUserToGroupResponse {
    const message = createBaseAddUserToGroupResponse();
    return message;
  },
};

messageTypeRegistry.set(AddUserToGroupResponse.$type, AddUserToGroupResponse);

function createBaseCreateServiceAccountRequest(): CreateServiceAccountRequest {
  return { $type: "idp.CreateServiceAccountRequest", identity: "" };
}

export const CreateServiceAccountRequest = {
  $type: "idp.CreateServiceAccountRequest" as const,

  encode(message: CreateServiceAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identity !== "") {
      writer.uint32(10).string(message.identity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateServiceAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateServiceAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identity = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateServiceAccountRequest>, I>>(base?: I): CreateServiceAccountRequest {
    return CreateServiceAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateServiceAccountRequest>, I>>(object: I): CreateServiceAccountRequest {
    const message = createBaseCreateServiceAccountRequest();
    message.identity = object.identity ?? "";
    return message;
  },
};

messageTypeRegistry.set(CreateServiceAccountRequest.$type, CreateServiceAccountRequest);

function createBaseCreateServiceAccountResponse(): CreateServiceAccountResponse {
  return { $type: "idp.CreateServiceAccountResponse", serviceAccount: undefined };
}

export const CreateServiceAccountResponse = {
  $type: "idp.CreateServiceAccountResponse" as const,

  encode(message: CreateServiceAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceAccount !== undefined) {
      ServiceAccount.encode(message.serviceAccount, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateServiceAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateServiceAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceAccount = ServiceAccount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateServiceAccountResponse>, I>>(base?: I): CreateServiceAccountResponse {
    return CreateServiceAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateServiceAccountResponse>, I>>(object: I): CreateServiceAccountResponse {
    const message = createBaseCreateServiceAccountResponse();
    message.serviceAccount = (object.serviceAccount !== undefined && object.serviceAccount !== null)
      ? ServiceAccount.fromPartial(object.serviceAccount)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateServiceAccountResponse.$type, CreateServiceAccountResponse);

function createBaseCreateBackupRequest(): CreateBackupRequest {
  return { $type: "idp.CreateBackupRequest" };
}

export const CreateBackupRequest = {
  $type: "idp.CreateBackupRequest" as const,

  encode(_: CreateBackupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBackupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBackupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateBackupRequest>, I>>(base?: I): CreateBackupRequest {
    return CreateBackupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBackupRequest>, I>>(_: I): CreateBackupRequest {
    const message = createBaseCreateBackupRequest();
    return message;
  },
};

messageTypeRegistry.set(CreateBackupRequest.$type, CreateBackupRequest);

function createBaseBackupFile(): BackupFile {
  return { $type: "idp.BackupFile", databaseFile: new Uint8Array(0), generatedAt: undefined };
}

export const BackupFile = {
  $type: "idp.BackupFile" as const,

  encode(message: BackupFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.databaseFile.length !== 0) {
      writer.uint32(10).bytes(message.databaseFile);
    }
    if (message.generatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.generatedAt), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BackupFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackupFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.databaseFile = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.generatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BackupFile>, I>>(base?: I): BackupFile {
    return BackupFile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BackupFile>, I>>(object: I): BackupFile {
    const message = createBaseBackupFile();
    message.databaseFile = object.databaseFile ?? new Uint8Array(0);
    message.generatedAt = object.generatedAt ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(BackupFile.$type, BackupFile);

function createBaseRestoreBackupResponse(): RestoreBackupResponse {
  return { $type: "idp.RestoreBackupResponse" };
}

export const RestoreBackupResponse = {
  $type: "idp.RestoreBackupResponse" as const,

  encode(_: RestoreBackupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreBackupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreBackupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RestoreBackupResponse>, I>>(base?: I): RestoreBackupResponse {
    return RestoreBackupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreBackupResponse>, I>>(_: I): RestoreBackupResponse {
    const message = createBaseRestoreBackupResponse();
    return message;
  },
};

messageTypeRegistry.set(RestoreBackupResponse.$type, RestoreBackupResponse);

function createBaseSyncFullRequest(): SyncFullRequest {
  return { $type: "idp.SyncFullRequest", user: [] };
}

export const SyncFullRequest = {
  $type: "idp.SyncFullRequest" as const,

  encode(message: SyncFullRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.user) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncFullRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyncFullRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SyncFullRequest>, I>>(base?: I): SyncFullRequest {
    return SyncFullRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SyncFullRequest>, I>>(object: I): SyncFullRequest {
    const message = createBaseSyncFullRequest();
    message.user = object.user?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(SyncFullRequest.$type, SyncFullRequest);

function createBaseSyncFullResponse(): SyncFullResponse {
  return { $type: "idp.SyncFullResponse" };
}

export const SyncFullResponse = {
  $type: "idp.SyncFullResponse" as const,

  encode(_: SyncFullResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncFullResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyncFullResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SyncFullResponse>, I>>(base?: I): SyncFullResponse {
    return SyncFullResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SyncFullResponse>, I>>(_: I): SyncFullResponse {
    const message = createBaseSyncFullResponse();
    return message;
  },
};

messageTypeRegistry.set(SyncFullResponse.$type, SyncFullResponse);

function createBasePasswordStrengthPolicy(): PasswordStrengthPolicy {
  return { $type: "idp.PasswordStrengthPolicy", tooSimpleUpperBound: 0, acceptableUpperBound: 0, goodUpperBound: 0 };
}

export const PasswordStrengthPolicy = {
  $type: "idp.PasswordStrengthPolicy" as const,

  encode(message: PasswordStrengthPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tooSimpleUpperBound !== 0) {
      writer.uint32(24).uint32(message.tooSimpleUpperBound);
    }
    if (message.acceptableUpperBound !== 0) {
      writer.uint32(32).uint32(message.acceptableUpperBound);
    }
    if (message.goodUpperBound !== 0) {
      writer.uint32(40).uint32(message.goodUpperBound);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordStrengthPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordStrengthPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.tooSimpleUpperBound = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.acceptableUpperBound = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.goodUpperBound = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PasswordStrengthPolicy>, I>>(base?: I): PasswordStrengthPolicy {
    return PasswordStrengthPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordStrengthPolicy>, I>>(object: I): PasswordStrengthPolicy {
    const message = createBasePasswordStrengthPolicy();
    message.tooSimpleUpperBound = object.tooSimpleUpperBound ?? 0;
    message.acceptableUpperBound = object.acceptableUpperBound ?? 0;
    message.goodUpperBound = object.goodUpperBound ?? 0;
    return message;
  },
};

messageTypeRegistry.set(PasswordStrengthPolicy.$type, PasswordStrengthPolicy);

function createBasePasswordStrengthRequest(): PasswordStrengthRequest {
  return { $type: "idp.PasswordStrengthRequest", userId: "", password: "" };
}

export const PasswordStrengthRequest = {
  $type: "idp.PasswordStrengthRequest" as const,

  encode(message: PasswordStrengthRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.password !== "") {
      writer.uint32(10).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordStrengthRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordStrengthRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PasswordStrengthRequest>, I>>(base?: I): PasswordStrengthRequest {
    return PasswordStrengthRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordStrengthRequest>, I>>(object: I): PasswordStrengthRequest {
    const message = createBasePasswordStrengthRequest();
    message.userId = object.userId ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

messageTypeRegistry.set(PasswordStrengthRequest.$type, PasswordStrengthRequest);

function createBasePasswordStrengthResponse(): PasswordStrengthResponse {
  return {
    $type: "idp.PasswordStrengthResponse",
    breached: false,
    strengthBits: 0,
    acceptable: false,
    policy: undefined,
  };
}

export const PasswordStrengthResponse = {
  $type: "idp.PasswordStrengthResponse" as const,

  encode(message: PasswordStrengthResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.breached === true) {
      writer.uint32(8).bool(message.breached);
    }
    if (message.strengthBits !== 0) {
      writer.uint32(17).double(message.strengthBits);
    }
    if (message.acceptable === true) {
      writer.uint32(24).bool(message.acceptable);
    }
    if (message.policy !== undefined) {
      PasswordStrengthPolicy.encode(message.policy, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PasswordStrengthResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasswordStrengthResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.breached = reader.bool();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.strengthBits = reader.double();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.acceptable = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.policy = PasswordStrengthPolicy.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PasswordStrengthResponse>, I>>(base?: I): PasswordStrengthResponse {
    return PasswordStrengthResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PasswordStrengthResponse>, I>>(object: I): PasswordStrengthResponse {
    const message = createBasePasswordStrengthResponse();
    message.breached = object.breached ?? false;
    message.strengthBits = object.strengthBits ?? 0;
    message.acceptable = object.acceptable ?? false;
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? PasswordStrengthPolicy.fromPartial(object.policy)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(PasswordStrengthResponse.$type, PasswordStrengthResponse);

function createBaseGeneratePasswordRequest(): GeneratePasswordRequest {
  return { $type: "idp.GeneratePasswordRequest", count: 0, family: 0 };
}

export const GeneratePasswordRequest = {
  $type: "idp.GeneratePasswordRequest" as const,

  encode(message: GeneratePasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).uint32(message.count);
    }
    if (message.family !== 0) {
      writer.uint32(16).int32(message.family);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneratePasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneratePasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.family = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GeneratePasswordRequest>, I>>(base?: I): GeneratePasswordRequest {
    return GeneratePasswordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeneratePasswordRequest>, I>>(object: I): GeneratePasswordRequest {
    const message = createBaseGeneratePasswordRequest();
    message.count = object.count ?? 0;
    message.family = object.family ?? 0;
    return message;
  },
};

messageTypeRegistry.set(GeneratePasswordRequest.$type, GeneratePasswordRequest);

function createBaseGeneratePasswordResponse(): GeneratePasswordResponse {
  return { $type: "idp.GeneratePasswordResponse", password: [] };
}

export const GeneratePasswordResponse = {
  $type: "idp.GeneratePasswordResponse" as const,

  encode(message: GeneratePasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.password) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneratePasswordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneratePasswordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.password.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GeneratePasswordResponse>, I>>(base?: I): GeneratePasswordResponse {
    return GeneratePasswordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeneratePasswordResponse>, I>>(object: I): GeneratePasswordResponse {
    const message = createBaseGeneratePasswordResponse();
    message.password = object.password?.map((e) => e) || [];
    return message;
  },
};

messageTypeRegistry.set(GeneratePasswordResponse.$type, GeneratePasswordResponse);

function createBaseFrontendConfig(): FrontendConfig {
  return { $type: "idp.FrontendConfig", identifier: "" };
}

export const FrontendConfig = {
  $type: "idp.FrontendConfig" as const,

  encode(message: FrontendConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FrontendConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFrontendConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FrontendConfig>, I>>(base?: I): FrontendConfig {
    return FrontendConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FrontendConfig>, I>>(object: I): FrontendConfig {
    const message = createBaseFrontendConfig();
    message.identifier = object.identifier ?? "";
    return message;
  },
};

messageTypeRegistry.set(FrontendConfig.$type, FrontendConfig);

function createBaseServiceAccount(): ServiceAccount {
  return { $type: "idp.ServiceAccount", idpEndpoint: "", caPem: "", clientCertPem: "", clientKeyPem: "" };
}

export const ServiceAccount = {
  $type: "idp.ServiceAccount" as const,

  encode(message: ServiceAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.idpEndpoint !== "") {
      writer.uint32(10).string(message.idpEndpoint);
    }
    if (message.caPem !== "") {
      writer.uint32(18).string(message.caPem);
    }
    if (message.clientCertPem !== "") {
      writer.uint32(26).string(message.clientCertPem);
    }
    if (message.clientKeyPem !== "") {
      writer.uint32(34).string(message.clientKeyPem);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.idpEndpoint = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.caPem = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientCertPem = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clientKeyPem = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ServiceAccount>, I>>(base?: I): ServiceAccount {
    return ServiceAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServiceAccount>, I>>(object: I): ServiceAccount {
    const message = createBaseServiceAccount();
    message.idpEndpoint = object.idpEndpoint ?? "";
    message.caPem = object.caPem ?? "";
    message.clientCertPem = object.clientCertPem ?? "";
    message.clientKeyPem = object.clientKeyPem ?? "";
    return message;
  },
};

messageTypeRegistry.set(ServiceAccount.$type, ServiceAccount);

/**
 * TOOD: Split the IdentityProvider service into an Authentication (Auth*) and a
 * UserManagement service (Add/Remove/Change)
 */
export interface IdentityProvider {
  AuthStart(request: DeepPartial<AuthStartRequest>, metadata?: grpc.Metadata): Promise<AuthStartResponse>;
  AuthUsername(request: DeepPartial<AuthUsernameRequest>, metadata?: grpc.Metadata): Promise<AuthUsernameResponse>;
  AuthPassword(request: DeepPartial<AuthPasswordRequest>, metadata?: grpc.Metadata): Promise<AuthPasswordResponse>;
  ChangePassword(
    request: DeepPartial<ChangePasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ChangePasswordResponse>;
  PasswordStrength(
    request: DeepPartial<PasswordStrengthRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PasswordStrengthResponse>;
  GeneratePassword(
    request: DeepPartial<GeneratePasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GeneratePasswordResponse>;
  AuthTOTP(request: DeepPartial<AuthTOTPRequest>, metadata?: grpc.Metadata): Promise<AuthTOTPResponse>;
  AddTOTPAuthenticator(
    request: DeepPartial<AddTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddTOTPAuthenticatorResponse>;
  RemoveTOTPAuthenticator(
    request: DeepPartial<RemoveTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveTOTPAuthenticatorResponse>;
  AddWebauthn(request: DeepPartial<AddWebauthnRequest>, metadata?: grpc.Metadata): Promise<AddWebauthnResponse>;
  AuthWebauthn(request: DeepPartial<AuthWebauthnRequest>, metadata?: grpc.Metadata): Promise<AuthWebauthnResponse>;
  RemoveWebauthn(
    request: DeepPartial<RemoveWebauthnRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveWebauthnResponse>;
  SendAuthMail(request: DeepPartial<SendAuthMailRequest>, metadata?: grpc.Metadata): Promise<SendAuthMailResponse>;
  AuthBackchannel(
    request: DeepPartial<AuthBackchannelRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AuthBackchannelResponse>;
  RemoveMail(request: DeepPartial<RemoveMailRequest>, metadata?: grpc.Metadata): Promise<RemoveMailResponse>;
  VerifyMail(request: DeepPartial<VerifyMailRequest>, metadata?: grpc.Metadata): Promise<VerifyMailResponse>;
  SendVerifyMail(
    request: DeepPartial<SendVerifyMailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SendVerifyMailResponse>;
  WatchEnrolment(
    request: DeepPartial<WatchEnrolmentRequest>,
    metadata?: grpc.Metadata,
  ): Observable<WatchEnrolmentEvent>;
  AuthImpersonate(
    request: DeepPartial<AuthImpersonateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AuthImpersonateResponse>;
  /**
   * GetUser returns the requested user object. Leaving the id field empty
   * returns the currently authenticated user. Getting data for a user other
   * than the currently authenticated one requires the idp.internal/pwinterop or
   * idp.internal/admin scopes.
   */
  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse>;
  /** GetSelf is deprecated. Use GetUser with an empty id instead. */
  GetSelf(request: DeepPartial<GetSelfRequest>, metadata?: grpc.Metadata): Promise<GetSelfResponse>;
  GetSessions(request: DeepPartial<GetSessionsRequest>, metadata?: grpc.Metadata): Promise<GetSessionsResponse>;
  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse>;
  GetBranding(request: DeepPartial<GetBrandingRequest>, metadata?: grpc.Metadata): Promise<Branding>;
}

export class IdentityProviderClientImpl implements IdentityProvider {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.AuthStart = this.AuthStart.bind(this);
    this.AuthUsername = this.AuthUsername.bind(this);
    this.AuthPassword = this.AuthPassword.bind(this);
    this.ChangePassword = this.ChangePassword.bind(this);
    this.PasswordStrength = this.PasswordStrength.bind(this);
    this.GeneratePassword = this.GeneratePassword.bind(this);
    this.AuthTOTP = this.AuthTOTP.bind(this);
    this.AddTOTPAuthenticator = this.AddTOTPAuthenticator.bind(this);
    this.RemoveTOTPAuthenticator = this.RemoveTOTPAuthenticator.bind(this);
    this.AddWebauthn = this.AddWebauthn.bind(this);
    this.AuthWebauthn = this.AuthWebauthn.bind(this);
    this.RemoveWebauthn = this.RemoveWebauthn.bind(this);
    this.SendAuthMail = this.SendAuthMail.bind(this);
    this.AuthBackchannel = this.AuthBackchannel.bind(this);
    this.RemoveMail = this.RemoveMail.bind(this);
    this.VerifyMail = this.VerifyMail.bind(this);
    this.SendVerifyMail = this.SendVerifyMail.bind(this);
    this.WatchEnrolment = this.WatchEnrolment.bind(this);
    this.AuthImpersonate = this.AuthImpersonate.bind(this);
    this.GetUser = this.GetUser.bind(this);
    this.GetSelf = this.GetSelf.bind(this);
    this.GetSessions = this.GetSessions.bind(this);
    this.Logout = this.Logout.bind(this);
    this.GetBranding = this.GetBranding.bind(this);
  }

  AuthStart(request: DeepPartial<AuthStartRequest>, metadata?: grpc.Metadata): Promise<AuthStartResponse> {
    return this.rpc.unary(IdentityProviderAuthStartDesc, AuthStartRequest.fromPartial(request), metadata);
  }

  AuthUsername(request: DeepPartial<AuthUsernameRequest>, metadata?: grpc.Metadata): Promise<AuthUsernameResponse> {
    return this.rpc.unary(IdentityProviderAuthUsernameDesc, AuthUsernameRequest.fromPartial(request), metadata);
  }

  AuthPassword(request: DeepPartial<AuthPasswordRequest>, metadata?: grpc.Metadata): Promise<AuthPasswordResponse> {
    return this.rpc.unary(IdentityProviderAuthPasswordDesc, AuthPasswordRequest.fromPartial(request), metadata);
  }

  ChangePassword(
    request: DeepPartial<ChangePasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ChangePasswordResponse> {
    return this.rpc.unary(IdentityProviderChangePasswordDesc, ChangePasswordRequest.fromPartial(request), metadata);
  }

  PasswordStrength(
    request: DeepPartial<PasswordStrengthRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PasswordStrengthResponse> {
    return this.rpc.unary(IdentityProviderPasswordStrengthDesc, PasswordStrengthRequest.fromPartial(request), metadata);
  }

  GeneratePassword(
    request: DeepPartial<GeneratePasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GeneratePasswordResponse> {
    return this.rpc.unary(IdentityProviderGeneratePasswordDesc, GeneratePasswordRequest.fromPartial(request), metadata);
  }

  AuthTOTP(request: DeepPartial<AuthTOTPRequest>, metadata?: grpc.Metadata): Promise<AuthTOTPResponse> {
    return this.rpc.unary(IdentityProviderAuthTOTPDesc, AuthTOTPRequest.fromPartial(request), metadata);
  }

  AddTOTPAuthenticator(
    request: DeepPartial<AddTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddTOTPAuthenticatorResponse> {
    return this.rpc.unary(
      IdentityProviderAddTOTPAuthenticatorDesc,
      AddTOTPAuthenticatorRequest.fromPartial(request),
      metadata,
    );
  }

  RemoveTOTPAuthenticator(
    request: DeepPartial<RemoveTOTPAuthenticatorRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveTOTPAuthenticatorResponse> {
    return this.rpc.unary(
      IdentityProviderRemoveTOTPAuthenticatorDesc,
      RemoveTOTPAuthenticatorRequest.fromPartial(request),
      metadata,
    );
  }

  AddWebauthn(request: DeepPartial<AddWebauthnRequest>, metadata?: grpc.Metadata): Promise<AddWebauthnResponse> {
    return this.rpc.unary(IdentityProviderAddWebauthnDesc, AddWebauthnRequest.fromPartial(request), metadata);
  }

  AuthWebauthn(request: DeepPartial<AuthWebauthnRequest>, metadata?: grpc.Metadata): Promise<AuthWebauthnResponse> {
    return this.rpc.unary(IdentityProviderAuthWebauthnDesc, AuthWebauthnRequest.fromPartial(request), metadata);
  }

  RemoveWebauthn(
    request: DeepPartial<RemoveWebauthnRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveWebauthnResponse> {
    return this.rpc.unary(IdentityProviderRemoveWebauthnDesc, RemoveWebauthnRequest.fromPartial(request), metadata);
  }

  SendAuthMail(request: DeepPartial<SendAuthMailRequest>, metadata?: grpc.Metadata): Promise<SendAuthMailResponse> {
    return this.rpc.unary(IdentityProviderSendAuthMailDesc, SendAuthMailRequest.fromPartial(request), metadata);
  }

  AuthBackchannel(
    request: DeepPartial<AuthBackchannelRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AuthBackchannelResponse> {
    return this.rpc.unary(IdentityProviderAuthBackchannelDesc, AuthBackchannelRequest.fromPartial(request), metadata);
  }

  RemoveMail(request: DeepPartial<RemoveMailRequest>, metadata?: grpc.Metadata): Promise<RemoveMailResponse> {
    return this.rpc.unary(IdentityProviderRemoveMailDesc, RemoveMailRequest.fromPartial(request), metadata);
  }

  VerifyMail(request: DeepPartial<VerifyMailRequest>, metadata?: grpc.Metadata): Promise<VerifyMailResponse> {
    return this.rpc.unary(IdentityProviderVerifyMailDesc, VerifyMailRequest.fromPartial(request), metadata);
  }

  SendVerifyMail(
    request: DeepPartial<SendVerifyMailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SendVerifyMailResponse> {
    return this.rpc.unary(IdentityProviderSendVerifyMailDesc, SendVerifyMailRequest.fromPartial(request), metadata);
  }

  WatchEnrolment(
    request: DeepPartial<WatchEnrolmentRequest>,
    metadata?: grpc.Metadata,
  ): Observable<WatchEnrolmentEvent> {
    return this.rpc.invoke(IdentityProviderWatchEnrolmentDesc, WatchEnrolmentRequest.fromPartial(request), metadata);
  }

  AuthImpersonate(
    request: DeepPartial<AuthImpersonateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AuthImpersonateResponse> {
    return this.rpc.unary(IdentityProviderAuthImpersonateDesc, AuthImpersonateRequest.fromPartial(request), metadata);
  }

  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse> {
    return this.rpc.unary(IdentityProviderGetUserDesc, GetUserRequest.fromPartial(request), metadata);
  }

  GetSelf(request: DeepPartial<GetSelfRequest>, metadata?: grpc.Metadata): Promise<GetSelfResponse> {
    return this.rpc.unary(IdentityProviderGetSelfDesc, GetSelfRequest.fromPartial(request), metadata);
  }

  GetSessions(request: DeepPartial<GetSessionsRequest>, metadata?: grpc.Metadata): Promise<GetSessionsResponse> {
    return this.rpc.unary(IdentityProviderGetSessionsDesc, GetSessionsRequest.fromPartial(request), metadata);
  }

  Logout(request: DeepPartial<LogoutRequest>, metadata?: grpc.Metadata): Promise<LogoutResponse> {
    return this.rpc.unary(IdentityProviderLogoutDesc, LogoutRequest.fromPartial(request), metadata);
  }

  GetBranding(request: DeepPartial<GetBrandingRequest>, metadata?: grpc.Metadata): Promise<Branding> {
    return this.rpc.unary(IdentityProviderGetBrandingDesc, GetBrandingRequest.fromPartial(request), metadata);
  }
}

export const IdentityProviderDesc = { serviceName: "idp.IdentityProvider" };

export const IdentityProviderAuthStartDesc: UnaryMethodDefinitionish = {
  methodName: "AuthStart",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthStartRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthStartResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthUsernameDesc: UnaryMethodDefinitionish = {
  methodName: "AuthUsername",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthUsernameRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthUsernameResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "AuthPassword",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthPasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthPasswordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderChangePasswordDesc: UnaryMethodDefinitionish = {
  methodName: "ChangePassword",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChangePasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ChangePasswordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderPasswordStrengthDesc: UnaryMethodDefinitionish = {
  methodName: "PasswordStrength",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PasswordStrengthRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PasswordStrengthResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderGeneratePasswordDesc: UnaryMethodDefinitionish = {
  methodName: "GeneratePassword",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GeneratePasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GeneratePasswordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthTOTPDesc: UnaryMethodDefinitionish = {
  methodName: "AuthTOTP",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthTOTPRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthTOTPResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAddTOTPAuthenticatorDesc: UnaryMethodDefinitionish = {
  methodName: "AddTOTPAuthenticator",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddTOTPAuthenticatorRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddTOTPAuthenticatorResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderRemoveTOTPAuthenticatorDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveTOTPAuthenticator",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveTOTPAuthenticatorRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveTOTPAuthenticatorResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAddWebauthnDesc: UnaryMethodDefinitionish = {
  methodName: "AddWebauthn",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddWebauthnRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddWebauthnResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthWebauthnDesc: UnaryMethodDefinitionish = {
  methodName: "AuthWebauthn",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthWebauthnRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthWebauthnResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderRemoveWebauthnDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveWebauthn",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveWebauthnRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveWebauthnResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderSendAuthMailDesc: UnaryMethodDefinitionish = {
  methodName: "SendAuthMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SendAuthMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SendAuthMailResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthBackchannelDesc: UnaryMethodDefinitionish = {
  methodName: "AuthBackchannel",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthBackchannelRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthBackchannelResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderRemoveMailDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveMailResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderVerifyMailDesc: UnaryMethodDefinitionish = {
  methodName: "VerifyMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return VerifyMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = VerifyMailResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderSendVerifyMailDesc: UnaryMethodDefinitionish = {
  methodName: "SendVerifyMail",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SendVerifyMailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SendVerifyMailResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderWatchEnrolmentDesc: UnaryMethodDefinitionish = {
  methodName: "WatchEnrolment",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return WatchEnrolmentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = WatchEnrolmentEvent.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAuthImpersonateDesc: UnaryMethodDefinitionish = {
  methodName: "AuthImpersonate",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthImpersonateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthImpersonateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetSelfDesc: UnaryMethodDefinitionish = {
  methodName: "GetSelf",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSelfRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSelfResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetSessionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetSessions",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSessionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSessionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderLogoutDesc: UnaryMethodDefinitionish = {
  methodName: "Logout",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LogoutRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LogoutResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderGetBrandingDesc: UnaryMethodDefinitionish = {
  methodName: "GetBranding",
  service: IdentityProviderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBrandingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Branding.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface IdentityProviderAdmin {
  ResetRisk(request: DeepPartial<ResetRiskRequest>, metadata?: grpc.Metadata): Promise<ResetRiskResponse>;
  DeleteUser(request: DeepPartial<DeleteUserRequest>, metadata?: grpc.Metadata): Promise<DeleteUserResponse>;
  CreateUser(request: DeepPartial<CreateUserRequest>, metadata?: grpc.Metadata): Promise<CreateUserResponse>;
  ListUsers(request: DeepPartial<ListUsersRequest>, metadata?: grpc.Metadata): Promise<ListUsersResponse>;
  /** AddUserClaim adds a new custom claim into a users "claim" map. */
  AddUserClaim(request: DeepPartial<AddUserClaimRequest>, metadata?: grpc.Metadata): Promise<AddUserClaimResponse>;
  /** DeleteUserClaim deletes a custom claim from a users "claim" map. */
  DeleteUserClaim(
    request: DeepPartial<DeleteUserClaimRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteUserClaimResponse>;
  /** AddUserToGroup adds a group to a user. */
  AddUserToGroup(
    request: DeepPartial<AddUserToGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddUserToGroupResponse>;
  NewUserPassword(
    request: DeepPartial<NewUserPasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NewUserPasswordResponse>;
  ImportUserPassword(
    request: DeepPartial<ImportUserPasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImportUserPasswordResponse>;
  CreateServiceAccount(
    request: DeepPartial<CreateServiceAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateServiceAccountResponse>;
  /** Backup & Resore */
  CreateBackup(request: DeepPartial<CreateBackupRequest>, metadata?: grpc.Metadata): Promise<BackupFile>;
  RestoreBackup(request: DeepPartial<BackupFile>, metadata?: grpc.Metadata): Promise<RestoreBackupResponse>;
}

export class IdentityProviderAdminClientImpl implements IdentityProviderAdmin {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ResetRisk = this.ResetRisk.bind(this);
    this.DeleteUser = this.DeleteUser.bind(this);
    this.CreateUser = this.CreateUser.bind(this);
    this.ListUsers = this.ListUsers.bind(this);
    this.AddUserClaim = this.AddUserClaim.bind(this);
    this.DeleteUserClaim = this.DeleteUserClaim.bind(this);
    this.AddUserToGroup = this.AddUserToGroup.bind(this);
    this.NewUserPassword = this.NewUserPassword.bind(this);
    this.ImportUserPassword = this.ImportUserPassword.bind(this);
    this.CreateServiceAccount = this.CreateServiceAccount.bind(this);
    this.CreateBackup = this.CreateBackup.bind(this);
    this.RestoreBackup = this.RestoreBackup.bind(this);
  }

  ResetRisk(request: DeepPartial<ResetRiskRequest>, metadata?: grpc.Metadata): Promise<ResetRiskResponse> {
    return this.rpc.unary(IdentityProviderAdminResetRiskDesc, ResetRiskRequest.fromPartial(request), metadata);
  }

  DeleteUser(request: DeepPartial<DeleteUserRequest>, metadata?: grpc.Metadata): Promise<DeleteUserResponse> {
    return this.rpc.unary(IdentityProviderAdminDeleteUserDesc, DeleteUserRequest.fromPartial(request), metadata);
  }

  CreateUser(request: DeepPartial<CreateUserRequest>, metadata?: grpc.Metadata): Promise<CreateUserResponse> {
    return this.rpc.unary(IdentityProviderAdminCreateUserDesc, CreateUserRequest.fromPartial(request), metadata);
  }

  ListUsers(request: DeepPartial<ListUsersRequest>, metadata?: grpc.Metadata): Promise<ListUsersResponse> {
    return this.rpc.unary(IdentityProviderAdminListUsersDesc, ListUsersRequest.fromPartial(request), metadata);
  }

  AddUserClaim(request: DeepPartial<AddUserClaimRequest>, metadata?: grpc.Metadata): Promise<AddUserClaimResponse> {
    return this.rpc.unary(IdentityProviderAdminAddUserClaimDesc, AddUserClaimRequest.fromPartial(request), metadata);
  }

  DeleteUserClaim(
    request: DeepPartial<DeleteUserClaimRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteUserClaimResponse> {
    return this.rpc.unary(
      IdentityProviderAdminDeleteUserClaimDesc,
      DeleteUserClaimRequest.fromPartial(request),
      metadata,
    );
  }

  AddUserToGroup(
    request: DeepPartial<AddUserToGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddUserToGroupResponse> {
    return this.rpc.unary(
      IdentityProviderAdminAddUserToGroupDesc,
      AddUserToGroupRequest.fromPartial(request),
      metadata,
    );
  }

  NewUserPassword(
    request: DeepPartial<NewUserPasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<NewUserPasswordResponse> {
    return this.rpc.unary(
      IdentityProviderAdminNewUserPasswordDesc,
      NewUserPasswordRequest.fromPartial(request),
      metadata,
    );
  }

  ImportUserPassword(
    request: DeepPartial<ImportUserPasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImportUserPasswordResponse> {
    return this.rpc.unary(
      IdentityProviderAdminImportUserPasswordDesc,
      ImportUserPasswordRequest.fromPartial(request),
      metadata,
    );
  }

  CreateServiceAccount(
    request: DeepPartial<CreateServiceAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateServiceAccountResponse> {
    return this.rpc.unary(
      IdentityProviderAdminCreateServiceAccountDesc,
      CreateServiceAccountRequest.fromPartial(request),
      metadata,
    );
  }

  CreateBackup(request: DeepPartial<CreateBackupRequest>, metadata?: grpc.Metadata): Promise<BackupFile> {
    return this.rpc.unary(IdentityProviderAdminCreateBackupDesc, CreateBackupRequest.fromPartial(request), metadata);
  }

  RestoreBackup(request: DeepPartial<BackupFile>, metadata?: grpc.Metadata): Promise<RestoreBackupResponse> {
    return this.rpc.unary(IdentityProviderAdminRestoreBackupDesc, BackupFile.fromPartial(request), metadata);
  }
}

export const IdentityProviderAdminDesc = { serviceName: "idp.IdentityProviderAdmin" };

export const IdentityProviderAdminResetRiskDesc: UnaryMethodDefinitionish = {
  methodName: "ResetRisk",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetRiskRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ResetRiskResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminDeleteUserDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUser",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeleteUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminCreateUserDesc: UnaryMethodDefinitionish = {
  methodName: "CreateUser",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminListUsersDesc: UnaryMethodDefinitionish = {
  methodName: "ListUsers",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListUsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminAddUserClaimDesc: UnaryMethodDefinitionish = {
  methodName: "AddUserClaim",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddUserClaimRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddUserClaimResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminDeleteUserClaimDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUserClaim",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteUserClaimRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeleteUserClaimResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminAddUserToGroupDesc: UnaryMethodDefinitionish = {
  methodName: "AddUserToGroup",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddUserToGroupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddUserToGroupResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminNewUserPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "NewUserPassword",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return NewUserPasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = NewUserPasswordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminImportUserPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "ImportUserPassword",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ImportUserPasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ImportUserPasswordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminCreateServiceAccountDesc: UnaryMethodDefinitionish = {
  methodName: "CreateServiceAccount",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateServiceAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateServiceAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminCreateBackupDesc: UnaryMethodDefinitionish = {
  methodName: "CreateBackup",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBackupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BackupFile.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const IdentityProviderAdminRestoreBackupDesc: UnaryMethodDefinitionish = {
  methodName: "RestoreBackup",
  service: IdentityProviderAdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return BackupFile.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RestoreBackupResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

/**
 * The Sync service exposes RPCs for synchronizing the built-in user and group
 * databases.
 */
export interface Sync {
  /**
   * SyncFull requests a full synchronization of all externally-managed
   * resources. Any resources not part of the request will be deleted.
   * Only fields that are externally-manageable are set, the others are ignored.
   * The following User fields are currently externally manageable:
   * id, status, profile, member_of, claim
   * The password field has a bit more special rules and is set if the algorithm
   * is ORACLE (but not if it is set to anything else). Also if the sync client
   * stops sending a password, the existing one is kept (this is different from
   * all other fields). If you are not using a password oracle, you should not
   * be setting the password field on your synchronized users.
   */
  SyncFull(request: DeepPartial<SyncFullRequest>, metadata?: grpc.Metadata): Promise<SyncFullResponse>;
}

export class SyncClientImpl implements Sync {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SyncFull = this.SyncFull.bind(this);
  }

  SyncFull(request: DeepPartial<SyncFullRequest>, metadata?: grpc.Metadata): Promise<SyncFullResponse> {
    return this.rpc.unary(SyncSyncFullDesc, SyncFullRequest.fromPartial(request), metadata);
  }
}

export const SyncDesc = { serviceName: "idp.Sync" };

export const SyncSyncFullDesc: UnaryMethodDefinitionish = {
  methodName: "SyncFull",
  service: SyncDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SyncFullRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SyncFullResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = (() => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => {
          return client.close();
        });
      });
      upStream();
    }).pipe(share());
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
