import { grpc } from "@improbable-eng/grpc-web";
import { mdiAccountGroup, mdiAccountOutline, mdiApplication, mdiMenuDown } from "@mdi/js";
import Icon from "@mdi/react";
import Avatar from "boring-avatars";
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { oauth2a } from "../api";
import { Brand } from "../Brand";
import { Link } from "../router";
import { Branding_LogoStyle, UserProfile } from "../generated/idp/api/idp";

const Container = styled.div`
  font-family: sans-serif;
  height: calc(100% - 30px);
  @media (min-width: 473px) {
    background: ${(p) => p.theme.branding?.background};
    padding: 15px;
  }
`;

const PrimarySubContainer = styled.div`
  @media (min-width: 473px) {
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
  height: 100%;
`;

const SideBySideContainer = styled.div`
  display: flex;
  height: calc(100% - 72px);
  @media (max-width: 472px) {
    height: calc(100% - 42px);
  }
`;

// TODO: Mobile menu
const NavLeftContainer = styled.div`
  min-width: 180px;
  background: white;
  border-bottom-left-radius: 10px;
  @media (max-width: 472px) {
    display: none;
  }
`;

const NavTop = styled.div<{ $brandingPadding: boolean }>`
  height: 72px;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.05);
  flex-shrink: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 2;
  padding-left: ${(props) => props.$brandingPadding ? "10px" : "0"};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  align-items: center;
`;

const NavTopRight = styled.div`
  display: block;
  cursor: pointer;
`;

const AvatarName = styled.div`
  font-style: normal;
  font-size: 20px;
  line-height: 36px;
  margin-left: 10px;
  @media (max-width: 472px) {
    display: none;
  }
`;

const LogoutIcon = styled(Icon)`
  margin-left: 5px;
`;

const Content = styled.div`
  padding: 30px 40px 30px 40px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f7f9;
  border-bottom-right-radius: 10px;
`;

const MenuBox = styled.div`
  height: 44px;
  display: flex;
  flex-flow: row;
  align-content: center;
  align-items: center;
`;

const MenuText = styled.div`
  font-size: 24px;
`;

const MenuIcon = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const AlignUser = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 10px;
`;

const DropDownContainer = styled.div`
  position: relative;
`;

const DropDownContent = styled.div`
  display: block;
  position: absolute;
  min-width: 200px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  right: 0;
  left: auto;
  z-index: 10;
`;

const SubA = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const UserImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;

export function Nav(props: {
  profile?: UserProfile;
  onLogout?: () => void;
  onPasswordChange?: () => void;
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<Boolean>(false);

  const [hasApps, setHasApps] = useState<boolean>(false);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        await oauth2a.ListClients({});
        if (!ignore) {
          setHasApps(true);
        }
      } catch (err) {
        if (err.code === grpc.Code.Unauthenticated) {
          setHasApps(true);
        }
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const hasAdmin = hasApps; // Currently just one

  let adminNav: React.ReactNode = null;
  if (hasAdmin) {
    adminNav = (
      <>
        <h3 style={{ marginLeft: 5 }}>Admin</h3>
        {hasApps ? (
          <>
            <Link activeColor={"#ccc"} path="/admin/apps">
              <MenuBox>
                <MenuIcon>
                  <Icon path={mdiApplication} size={1} />
                </MenuIcon>
                <MenuText>Apps</MenuText>
              </MenuBox>
            </Link>
            <Link activeColor={"#ccc"} path="/admin/users">
              <MenuBox>
                <MenuIcon>
                  <Icon path={mdiAccountGroup} size={1} />
                </MenuIcon>
                <MenuText>Users</MenuText>
              </MenuBox>
            </Link>
          </>
        ) : null}
      </>
    );
  }

  return (
    <Container>
      <PrimarySubContainer>
        <NavTop $brandingPadding={theme.branding?.logoStyle === Branding_LogoStyle.BRAND_NAME_ONLY}>
          <Brand />
          <NavTopRight
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <AlignUser>
              {props.profile === undefined || props.profile?.picture === "" ? (
                <Avatar name={props.profile?.name ?? ""} size={32} variant="marble" />
              ) : (
                <UserImage src={props.profile.picture} />
              )}
              <AvatarName>{props.profile?.name ?? ""}</AvatarName>
              <LogoutIcon path={mdiMenuDown} size={1} />
            </AlignUser>
            <DropDownContainer>
              {isOpen && (
                <DropDownContent>
                  <SubA onClick={props.onPasswordChange}>Passwort ändern</SubA>
                  <SubA onClick={props.onLogout}>Abmelden</SubA>
                </DropDownContent>
              )}
            </DropDownContainer>
          </NavTopRight>
        </NavTop>
        <SideBySideContainer>
          <NavLeftContainer>
            <Link activeColor={"#ccc"} path="/">
              <MenuBox>
                <MenuIcon>
                  <Icon path={mdiAccountOutline} size={1} />
                </MenuIcon>
                <MenuText>Profil</MenuText>
              </MenuBox>
            </Link>
            {adminNav}
          </NavLeftContainer>
          <Content>{props.children}</Content>
        </SideBySideContainer>
      </PrimarySubContainer>
    </Container>
  );
}
